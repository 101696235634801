import { motion } from "framer-motion";
import Icon from "@material-ui/core/Icon";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import React, { useMemo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CommonTable from "../../components/table/CommonTable";
import StatusForm from "./StatusForm";
import axios from "app/utils/AxiosConfig";
import Constants from "app/utils/Constants";
import CommonView from "../../components/commonView/CommonView";
import CommonDialog from "app/components/dialog/CommonDialog";
import Store from "app/utils/Store";
import ConfirmDialog from "app/components/dialog/ConfirmDialog";
import { Button } from "@material-ui/core";
import CreateWorkflow from "./CreateWorkflow";
let logged_user = Store.USER;
const access = logged_user && logged_user.access ? logged_user.access : {};
export default function StatusList(props) {
  const dispatch = useDispatch();
  // const user = useSelector(({ contactsApp }) => contactsApp.user);
  const user = [];
  const [data, setData] = useState([]);
  const [page, setPage] = useState("list");
  const [values, setValues] = useState({});
  const [statusDialog, setStatusDialog] = useState(false);
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [statusSelected, setStatusSelected] = useState([]);
  const [openWorkflowChecking, setOpenWorkflowChecking] = useState(false);
  const [dataWorkflow, setDataWorkflow] = useState([]);
  const [openCreateWorkflow, setOpenCreateWorkflow] = useState(false);
  const [workflowSelected, setWorkflowSelected] = useState({});
  const [deleteWorkflowDialog, setDeleteWorkflowDialog] = useState(false);
  const translateProposalFields = {
    dt_emission: "Data de ganho",
    month_sell: "Mês de venda",
    year_sell: "Ano de venda",
    number: "Nº PI/PP",
    fk_id_square: "Praça",
    fk_id_responsable: "Responsável",
    fk_id_client: "Cliente",
    fk_id_vehicle: "Veículo",
    fk_id_agency: "Agência",
    month_placement: "Mês de veiculação",
    sponsor_vehicle: "Representante do veículo",
    comission_value_negotiated: "VL. Comissão Negociado",
    dt_received_vehicle: "Data de Recebimento do Veículo",
    comission_value: "VL. Comissão",
    incentive: "Incentivo",
    taxes_vehicle: "Impostos do veículo",
    taxes_nf_percent: "%Impostos NF",
    taxes_nf: "VL. Imposto NF",
    nf_mosaic: "Nº NF Representante",
    nf_due_date: "Data de Vencimento da NF",
    dt_received_mosaic: "Data de Recebimento do Representante",
    received_value: "Valor Recebido",
    incentive_nf: "NF Incentivo",
    dt_received_comission_executive: "Data de Pagamento de Comissão de Executivo",
    dt_received_comission_directors: "Data de Pagamento de Comissão de Diretores",
    nf: "Nº NF",
  };

  const columns = useMemo(
    () => [
      {
        Header: "Nome",
        accessor: "name",
        className: "font-medium",
        sortable: true,
      },
      {
        Header: "Setor",
        accessor: "sector",
        sortable: true,
      },
      {
        Header: "Acesso",
        accessor: "roles",
        sortable: true,
        Cell: ({ row }) => <div className="flex items-center">{(row.original.fk_id_role || []).join(", ")}</div>,
      },
      {
        Header: "Campos Obrigatórios das Propostas",
        accessor: "fieldProposals",
        sortable: true,
        Cell: ({ row }) => (
          <div className="flex items-center">
            {(row.original.fieldsProposals || []).map((field) => translateProposalFields[field]).join(", ")}
          </div>
        ),
      },
      {
        id: "action",
        width: 128,
        sortable: false,
        Cell: ({ row }) => (
          <div className="flex items-center">
            {access.status_update ? (
              <IconButton
                onClick={(ev) => {
                  setValues(row.original);
                  setPage("add");
                }}
              >
                <Icon>edit</Icon>
              </IconButton>
            ) : null}
            {access.status_delete ? (
              <IconButton
                onClick={(ev) => {
                  setStatusSelected(row.original);
                  setDeleteDialog(true);
                }}
              >
                <Icon>delete</Icon>
              </IconButton>
            ) : null}
          </div>
        ),
      },
    ],
    [dispatch, user.starred]
  );

  const considerDates = [
    "",
    "Data de Mudança do Status",
    "Data de Ganho",
    "Data de recebimento do veículo",
    "Data de recebimento do representante",
  ];

  const columnsWorkflow = useMemo(
    () => [
      {
        Header: "Status",
        accessor: "status",
        className: "font-medium",
        sortable: true,
      },
      {
        Header: "Número da Etapa",
        accessor: "step",
        sortable: true,
      },
      {
        Header: "Data a considerar",
        accessor: "consider_date",
        sortable: true,
        Cell: ({ row }) => <div className="flex items-center">{considerDates[row.original.consider_date || 0]}</div>,
      },
      {
        Header: "Enviar Email",
        accessor: "send_mail",
        sortable: true,
        Cell: ({ row }) => <div className="flex items-center">{row.original.send_mail ? "Sim" : "Não"}</div>,
      },

      {
        id: "action",
        width: 128,
        sortable: false,
        Cell: ({ row }) => (
          <div className="flex items-center">
            <IconButton
              onClick={(ev) => {
                setWorkflowSelected(row.original);
                setOpenCreateWorkflow(true);
              }}
            >
              <Icon>edit</Icon>
            </IconButton>
            <IconButton
              onClick={(ev) => {
                setWorkflowSelected(row.original);
                setDeleteWorkflowDialog(true);
              }}
            >
              <Icon>delete</Icon>
            </IconButton>
          </div>
        ),
      },
    ],
    [dispatch, user.starred]
  );
  const onAdd = () => {
    setValues({});
    setPage("add");
  };

  const onAddWorkflow = () => {
    setOpenCreateWorkflow(true);
    setWorkflowSelected(true);
  };

  useEffect(() => {
    getData();
    getWorkflows();
  }, []);

  const deleteStatus = (id) => {
    const data = { id_status: statusSelected.id_status };

    axios.post(Constants.APIEndpoints.STATUS + "/deleteStatus", data).then((res) => {
      getData();
      setDeleteDialog(false);
    });
  };

  const getWorkflows = () => {
    axios.get(Constants.APIEndpoints.STATUS + "/getworkflows").then((res) => {
      setDataWorkflow(res.data);
    });
  };

  const getData = () => {
    axios.get(Constants.APIEndpoints.STATUS + "/getAllStatus").then((res) => {
      setData(res.data[0]);
    });
  };

  const deleteWorkflow = () => {
    axios.post(Constants.APIEndpoints.STATUS + "/deleteworkflow", workflowSelected).then((res) => {
      getWorkflows();
    });
  };

  const rightSide = (
    <div style={{ width: "20%", display: "flex", justifyContent: "center" }}>
      <Button variant="contained" color="white" onClick={() => setOpenWorkflowChecking(true)}>
        <span className="hidden sm:flex">Configurar Workflow Checking</span>
      </Button>
    </div>
  );

  return (
    <motion.div initial={{ y: 20, opacity: 0 }} animate={{ y: 0, opacity: 1, transition: { delay: 0.2 } }}>
      <CommonDialog
        open={openWorkflowChecking}
        onClose={() => setOpenWorkflowChecking(false)}
        title="Configurar Workflow"
        maxWidth={true}
        width="lg"
      >
        <CommonTable
          id={"workflow_checking_list"}
          columns={columnsWorkflow}
          data={dataWorkflow}
          newText="Adicionar Nova Etapa"
          onAdd={onAddWorkflow}
          noSearch={true}
        />
      </CommonDialog>
      {openCreateWorkflow && (
        <CreateWorkflow
          status={data}
          setWorkflowSelected={setWorkflowSelected}
          workflowSelected={workflowSelected}
          open={openCreateWorkflow}
          getWorkflows={getWorkflows}
          onClose={() => setOpenCreateWorkflow(false)}
        />
      )}
      {deleteDialog ? (
        <ConfirmDialog title="Deseja deletar esse Status?" cancel={() => setDeleteDialog(false)} confirm={deleteStatus} />
      ) : null}

      {deleteWorkflowDialog ? (
        <ConfirmDialog title="Deseja deletar esse Workflow?" cancel={() => setDeleteWorkflowDialog(false)} confirm={deleteWorkflow} />
      ) : null}
      {page == "list" ? (
        <CommonTable
          id="status_list"
          rightSide={rightSide}
          columns={columns}
          data={data}
          icon="people"
          newText="Adicionar Novo Status"
          onAdd={access.status_create ? onAdd : null}
          headerTitle="Status"
          onRowClick={(ev, row) => {
            if (row) {
              //            dispatch(openEditContactDialog(row.original));
            }
          }}
        />
      ) : (
        <StatusForm values={values} setPage={setPage} getData={getData} />
      )}
    </motion.div>
  );
}
