import React, { useEffect, useState } from 'react'
import '../Leads.css'
import { Icon, IconButton, Tooltip, Typography } from '@material-ui/core'
import moment from 'moment'
import DownloadFiles from 'app/utils/DownloadFiles'
import ConfirmDialog from "app/components/dialog/ConfirmDialog";
import CommonDialog from 'app/components/dialog/CommonDialog'
import axios from 'app/utils/AxiosConfig';
import Constants from 'app/utils/Constants';
import { eventBus } from 'app/utils/EventListener'
import CommonForm from 'app/components/form/CommonForm'

const Info = ({ visuplac, lead, setOpenWinCard, getData, setOpenLossCard }) => {
    const [winCardDialog, setWinCardDialog] = useState(false)
    const [winConfirmCardDialog, setWinConfirmCardDialog] = useState(false)
    const [lossCardDialog, setLossCardDialog] = useState(false)
    const [progressConfirmCard, setProgressConfirmCard] = useState(false)
    const [justifies, setJustifies] = useState([])
    const [confirmNotRealizedDialog, setConfirmNotRealizedDialog] = useState(false)
    const [archiveLeadDialog, setArchiveLeadDialog] = useState(false)
    
    useEffect(() => {
        axios
            .get(
                Constants.APIEndpoints.JUSTIFY + "/getalljustify")
            .then((res) => {
                setJustifies(res.data)
            })
    }, [])

    const winLead = () => {
        axios.post(Constants.APIEndpoints.KANBAN + '/winlead', lead).then(res => {
            getData({ inProgress: true })
            eventBus.emit('won-modal-card', {})
        })
    }
    const lossCard = (values) => {
        values.fk_id_card = lead.id_cards
        axios.post(Constants.APIEndpoints.KANBAN + '/losscard', values).then(res => {
            eventBus.emit('lose-modal-card', {})
            getData({ inProgress: true })
        })
    }

    const confirmWinLead = (win) => {
        axios.post(Constants.APIEndpoints.LEADS + '/confirmwinlead', { win, id_leads: lead.id_leads }).then(res => {
            eventBus.emit('update-column', {})
            eventBus.emit('lose-modal-card', {})
            setWinConfirmCardDialog(false)
        })
    }

    const confirmNotRealized = () => {
        axios.post(Constants.APIEndpoints.LEADS + '/confirmnotrealized', lead).then(res => {
            eventBus.emit('lose-modal-card', {})
            eventBus.emit('update-column', {})

        })
    }

    const archiveLead = () => {
        axios.post(Constants.APIEndpoints.LEADS + '/archivelead', lead).then(res => {
            eventBus.emit('lose-modal-card', {})
            eventBus.emit('update-column', {})
        })
    }
    const getMiddlesNames = (middles) => {
        const middleList = ['', "TV",
            "INTERNET",
            "RADIO",
            "OOH",
            "DOOH",
            "JORNAL",
            "REVISTA",
            "PORTAIS",
            "REDES SOCIAIS",
            "VERTICAIS",
            "WIFI",
            "REDE DE APPS",
            "REDE DE CONTEÚDO",
            "REDE DE VÍDEO",
            "MOBILIDADE URBANA",
            "TV ABERTA",
            "PAY TV",
            "CTV"]

        const middlesName = middles.map(middle => middleList[middle])
        return middlesName.join(', ')
    }



    return (
        <div style={{ padding: 10 }}>
            {
                winCardDialog ? (
                    <ConfirmDialog title="Deseja dar ganho?" cancel={() => setWinCardDialog(false)} confirm={winLead} />
                ) : null
            }
            {
                winConfirmCardDialog ? (
                    <ConfirmDialog title="Deseja confirmar o ganho?" cancel={() => setWinConfirmCardDialog(false)} confirm={() => confirmWinLead(true)} />
                ) : null
            }
            {
                progressConfirmCard ? (
                    <ConfirmDialog title="Deseja colocar ele em andamento novamente?" cancel={() => setProgressConfirmCard(false)} confirm={() => confirmWinLead(false)} />
                ) : null
            }
            {
                confirmNotRealizedDialog ? (
                    <ConfirmDialog title="Esta campanha não foi realizada?" cancel={() => setConfirmNotRealizedDialog(false)} confirm={() => confirmNotRealized()} />
                ) : null
            }
             {
                archiveLeadDialog ? (
                    <ConfirmDialog title="Deseja arquivar esse lead?" cancel={() => setArchiveLeadDialog(false)} confirm={() => archiveLead()} />
                ) : null
            }

            <CommonDialog
                open={lossCardDialog}
                fullWidth={true}
                maxWidth={'xl'}
                onClose={() => setLossCardDialog(false)}>
                <CommonForm
                    fields={[
                        {
                            label: 'Justificativa',
                            col: 12,
                            name: 'fk_id_justify',
                            type: 'select',
                            options: justifies.filter(j => j.type_justify == 2).map(justify => ({ value: justify.id_justify, label: justify.name }))
                        },
                    ]}
                    onSubmit={lossCard}
                />
            </CommonDialog>

            {['finished', 'not_realized'].includes(lead.status) || lead.archived? (
                <div>
                    <Tooltip title={<h5>Voltar para em Andamento</h5>}>
                        <IconButton onClick={() =>
                            setProgressConfirmCard(true)
                        }>
                            <Icon style={{ color: 'red' }}>
                                cached
                            </Icon>
                        </IconButton>
                    </Tooltip>
                </div>
            ) : null}
            {lead.proposals_progress == 0 && lead.proposals_win == 0 && lead.proposals_loss == 0  && !lead.archived  ? (
                <div>
                    <Tooltip title={<h5>Arquivar</h5>}>
                        <IconButton onClick={() =>
                            setArchiveLeadDialog(true)
                        }>
                            <Icon style={{ color: 'black' }}>
                                archive
                            </Icon>
                        </IconButton>
                    </Tooltip>
                </div>
            ) : null}
            
            <div className='infoDiv'>
                <Icon>
                    calendar_today
                </Icon>
                <Typography>DATA DE CRIAÇÃO </Typography>
            </div>
            <div className='infoValue'>{moment(lead.dt_cad).format('DD/MM/YYYY HH:mm:ss')}</div>
            <div className='infoDiv'>
                <Icon>
                    receipt
                </Icon>
                <Typography>CAMPANHA</Typography>
            </div>
            {lead.campaign && (<div className='infoValue'>{lead.campaign}</div>)}
            <div className='infoDiv'>
                <Icon>
                    people
                </Icon>
                <Typography>CLIENTE</Typography>
            </div>
            {lead.client && (<div className='infoValue'>{lead.client}</div>)}
            <div className='infoDiv'>
                <Icon>
                    people
                </Icon>
                <Typography>AGÊNCIA</Typography>
            </div>
            {lead.agency && (<div className='infoValue'>{lead.agency}</div>)}
            <div className='infoDiv'>
                <Icon>
                    money
                </Icon>
                <Typography>VALOR</Typography>
            </div>
            {lead.value && (<div className='infoValue'>{parseFloat(lead.value).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</div>)}
            <div className='infoDiv'>
                <Icon>
                    calendar_today
                </Icon>
                <Typography>PERÍODO</Typography>
            </div>
            {lead.dt_start && (<div className='infoValue'>{moment(lead.dt_start).format('DD/MM/YYYY')} - {lead.dt_end ? moment(lead.dt_end).format('DD/MM/YYYY') : ''}</div>)}
            <div className='infoDiv'>
                <Icon>
                    bookmark
                </Icon>
                <Typography>ETIQUETAS</Typography>
            </div>
            <div className='flex'>
                {(lead.tags || []).map(tag => (
                    <div style={{
                        backgroundColor: tag.color, height: 20, width: 80
                        , margin: 4, borderRadius: 10, display: 'flex', color: 'white', fontSize: 10,
                        justifyContent: 'center', alignItems: 'center'
                    }}>{tag.name}</div>
                ))}
            </div>

            {lead.observation && (
                <div className='infoDiv'
                >
                    <Icon>
                        description
                    </Icon>
                    <Typography>DETALHAMENTO DA CAMPANHA</Typography>
                </div>)}
            <div style={{
                maxWidth: '100%',
                overflowWrap: 'break-word'
            }}

                dangerouslySetInnerHTML={{ __html: lead.observation }} />

        </div>
    )
}

export default Info