import React, { useState } from "react"
import ProposalInfo from "./ProposalInfo"
import ProposalProducts from "./ProposalProducts"
import ProposalHistoric from "./ProposalHistoric"
import CommonHeader from "app/components/table/CommonHeader"
import { useResizeDetector } from "react-resize-detector"
import ProposalFiles from "./ProposalFiles"
import Store from 'app/utils/Store'
import ProposalFinancesView from "./ProposalFinancesView"
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import { saveAs } from 'file-saver';
import { Tooltip } from '@material-ui/core';
import IconButton from "@material-ui/core/IconButton";
import moment from "moment-timezone";
import { ToastContainer, toast } from 'react-toastify';
import Constants from "app/utils/Constants";
import ProposalTimeCheck from "./ProposalTimeCheck"

let logged_user = Store.USER
const access = logged_user && logged_user.access ? logged_user.access : {}

const ProposalView = ({ proposal, setPage }) => {
    const [pageActive, setPageActive] = useState(0)
    const { width, ref } = useResizeDetector();

    const exportProposal = (proposal) => {
        const file = `negócio_${proposal.number}-${moment().format('DDMMYYYY_HH:mm:ss')}.pdf`
        const token = sessionStorage.getItem('token');
        const notification = toast("Gerando Relatório");
        fetch(Constants.APIEndpoints.PROPOSAL + '/buildproposalexcel',
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-Access-Token': token
                },
                body: JSON.stringify({
                    id: proposal.id_proposals
                })
            }).then(results => {
                return results.blob()
            }).then(response => {
                saveAs(response, file);
                toast.dismiss(notification)
            })
    }
    return (
        <div ref={ref}>
            <ToastContainer
                position="bottom-right"
                autoClose={4500}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss={false}
                draggable
                pauseOnHover={false}
            />
            <CommonHeader title=''
                width={width}
                miniVersion={true}
                onBack={() => setPage('list')}
                headerFilters={(
                    <div className="btnHeaders"
                    >
                        <Tooltip title={<h5>Exportar Negócio</h5>} placement="right">
                            <IconButton
                                onClick={(ev) => {
                                    exportProposal(proposal)
                                }}
                                style={{
                                    color: 'white',
                                    marginRight: 10
                                }}
                            >
                                <PictureAsPdfIcon />
                            </IconButton>
                        </Tooltip>
                        <div
                            onClick={() => setPageActive(0)}
                            style={{
                                backgroundColor: pageActive == 0 ? '#aba6a6' : ''
                            }}>INFORMAÇÕES BÁSICAS</div>
                        <div
                            onClick={() => setPageActive(1)}
                            style={{
                                backgroundColor: pageActive == 1 ? '#aba6a6' : ''
                            }}
                        >PRODUTOS</div>
                        <div
                            onClick={() => setPageActive(2)}
                            style={{
                                backgroundColor: pageActive == 2 ? '#aba6a6' : ''
                            }}
                        >ARQUIVOS</div>
                        <div
                            onClick={() => setPageActive(3)}
                            style={{
                                backgroundColor: pageActive == 3 ? '#aba6a6' : ''
                            }}
                        >HISTÓRICO</div>
                        {access.proposal_finances ? (
                            <div
                                onClick={() => setPageActive(4)}
                                style={{
                                    backgroundColor: pageActive == 4 ? '#aba6a6' : ''
                                }}
                            >FINANCEIRO</div>
                        ) : null}
                            <div
                                onClick={() => setPageActive(5)}
                                style={{
                                    backgroundColor: pageActive == 5 ? '#aba6a6' : ''
                                }}
                            >TIME CHECK</div>
                    </div>
                )
                }
            />
            <div>
                {pageActive == 0 ? (
                    <ProposalInfo proposal={proposal} />
                ) :
                    pageActive == 1 ?
                        (
                            <ProposalProducts proposal={proposal} />
                        ) :
                        pageActive == 2 ? (
                            <ProposalFiles proposal={proposal} />
                        ) : pageActive == 3 ?
                            (
                                <ProposalHistoric proposal={proposal} />
                            ) : pageActive == 4 ? (
                                <ProposalFinancesView id_proposals={proposal.id_proposals} />
                            ) : (
                                <ProposalTimeCheck proposal={proposal} />
                            )
                }
            </div >
        </div >
    )
}

export default ProposalView