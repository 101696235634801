import React, { useEffect, useState } from "react";
import { Box, Grid, Icon, IconButton } from "@material-ui/core";
import axios from "app/utils/AxiosConfig";
import Constants from "app/utils/Constants";
import moment from "moment-timezone";
import Store from "app/utils/Store";
const user = Store.USER;
const ProposalTimeCheck = ({ proposal }) => {
  const [timeCheck, setTimeCheck] = useState([]);
  const [editMode, setEditMode] = useState([]);
  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    axios.get(Constants.APIEndpoints.PROPOSAL + "/gettimecheck/" + proposal.id_proposals).then((res) => {
      setTimeCheck(res.data);
    });
  };

  const getDiffTime = (time) => {
    const lastDate = (timeCheck || []).find((s) => s.step < time.step);
    if (lastDate) {
      const diff = moment(time.dt_cad).diff(moment(lastDate.dt_cad), "seconds");
      const days = parseInt(diff / 86400);
      return `${days} dia ${days == 1 ? "" : "s"}`;
    }
    return "";
  };

  const saveDate = () => {
    axios.post(Constants.APIEndpoints.PROPOSAL + "/updatetimecheck", editMode).then((res) => {
      setEditMode([]);
      getData();
    });
  };

  return (
    <div>
      <div style={{ marginTop: 60 }}>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={1} style={{ backgroundColor: "white" }}>
            <Grid item xs={12}>
              <div className="cardProposal">
                <div>
                  <table style={{ width: "100%" }}>
                    <thead>
                      <tr>
                        <th
                          style={{
                            color: "black",
                            width: "25%",
                            textAlign: "start",
                            fontSize: 16,
                          }}
                        >
                          Status
                        </th>
                        <th
                          style={{
                            color: "black",
                            width: "25%",
                            textAlign: "start",
                            fontSize: 16,
                          }}
                        >
                          Usuário
                        </th>
                        <th
                          style={{
                            color: "black",
                            width: "25%",
                            textAlign: "start",
                            fontSize: 16,
                          }}
                        >
                          Data
                        </th>
                        <th
                          style={{
                            color: "black",
                            width: "25%",
                            textAlign: "start",
                            fontSize: 16,
                          }}
                        >
                          Tempo Até o Status
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {timeCheck.map((time) => (
                        <tr key={time.id_change_proposal_status} style={{ color: "black" }}>
                          <td className="table_td" style={{ textAlign: "start" }}>
                            {time.name}
                          </td>
                          <td className="table_td" style={{ textAlign: "start" }}>
                            {time.user}
                          </td>
                          <td className="table_td" style={{ textAlign: "start" }}>
                            <div>
                              {user.role == "admin" || user.access?.proposal_change_date_status ? (
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  {editMode.id_proposals_change_status === time.id_proposals_change_status ? (
                                    <div>
                                      <input
                                        type="date"
                                        value={moment(editMode.dt_cad).format("YYYY-MM-DD")}
                                        onChange={(evt) =>
                                          setEditMode((prev) => ({
                                            ...prev,
                                            dt_cad: evt.target.value,
                                          }))
                                        }
                                      ></input>
                                    </div>
                                  ) : (
                                    <div>{moment(time.dt_cad).format("DD/MM/YYYY")}</div>
                                  )}

                                  <IconButton
                                    style={{
                                      justifyContent: "start",
                                      width: 45,
                                      borderRadius: 0,
                                    }}
                                    onClick={(ev) => {
                                      editMode.id_proposals_change_status === time.id_proposals_change_status
                                        ? saveDate()
                                        : setEditMode(time);
                                    }}
                                  >
                                    <Icon>{editMode.id_proposals_change_status === time.id_proposals_change_status ? "save" : "edit"}</Icon>
                                  </IconButton>
                                </div>
                              ) : (
                                <div>{moment(time.dt_cad).format("DD/MM/YYYY")}</div>
                              )}
                            </div>
                          </td>
                          <td className="table_td" style={{ textAlign: "start" }}>
                            {getDiffTime(time)}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </Grid>
          </Grid>
        </Box>
      </div>
    </div>
  );
};

export default ProposalTimeCheck;
