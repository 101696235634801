import i18next from 'i18next';
import ar from './navigation-i18n/ar';
import en from './navigation-i18n/en';
import tr from './navigation-i18n/tr';
import Store from 'app/utils/Store'

i18next.addResourceBundle('en', 'navigation', en);
i18next.addResourceBundle('tr', 'navigation', tr);
i18next.addResourceBundle('ar', 'navigation', ar);

let user = Store.USER
const access = user && user.access ? user.access : {}
const navigationConfig = [
  {
    id: 'menu',
    title: 'SysDrive',
    translate: 'SYS',
    type: 'group',
    icon: 'apps',
    children: [
      {
        id: 'home',
        title: 'Home',
        translate: 'Home',
        type: 'item',
        icon: 'home',
        url: '/home',
      },
      access.menu_crm ?
        {
          id: 'crm',
          title: 'CRM',
          translate: 'CRM',
          type: 'collapse',
          icon: 'dashboard',
          children: [
            access.menu_home ?
              {
                id: 'home',
                title: 'Leads',
                translate: 'Leads',
                type: 'item',
                icon: 'lightbulb',
                url: '/nleads',
              } : {},
            access.menu_lead_visuplac ?
              {
                id: 'home',
                title: 'Leads',
                translate: 'Leads ',
                type: 'item',
                icon: 'emoji_objects',
                url: '/leadsv',
              } : {},
            access.menu_calendar ?
              {
                id: 'calendar',
                title: 'Calendário',
                translate: 'Calendário',
                type: 'item',
                icon: 'date_range',
                url: '/calendar',
              } : {},
            access.menu_mailings ?
              {
                id: 'contacts',
                title: 'Contatos',
                translate: 'Contatos',
                type: 'item',
                icon: 'mail',
                url: '/contacts',
              } : {},
            access.menu_tech_report ?
              {
                id: 'report_tech',
                title: 'Relatório dos Usuários',
                translate: 'Relatório dos Usuários',
                type: 'item',
                icon: 'dashboard',
                url: '/techreport',
              } : {},
          ]
        } : {},
      access.menu_admin ?
        {
          id: 'administrativo',
          title: 'Administrativo',
          translate: 'Administrativo',
          type: 'collapse',
          icon: 'dashboard',
          children: [
            access.menu_users ?
              {
                id: 'users',
                title: 'Usuários',
                translate: 'Usuários',
                type: 'item',
                icon: 'person',
                url: '/usuarios',
                visible: false
              }
              : {},

            access.menu_access ?
              {
                id: 'access',
                title: 'Acesso',
                translate: 'Acceso',
                type: 'item',
                icon: 'dock',
                url: '/access',
              } : {},
            access.menu_logs ?
              {
                id: 'logs',
                title: 'Logs',
                translate: 'Logs',
                type: 'item',
                icon: 'dock',
                url: '/logs',
              } : {},
          ]
        } : {},
      access.menu_registers ?
        {
          id: 'comercial',
          title: 'Cadastros',
          translate: 'Cadastros',
          type: 'collapse',
          icon: 'people',
          children: [
            access.menu_vehicles ?
              {
                id: 'vehicles',
                title: 'Veículos',
                translate: 'Veículos',
                type: 'item',
                icon: 'directions_car',
                url: '/veiculos',
              } : {},

            access.menu_groups ?
              {
                id: 'groups',
                title: 'Grupos',
                translate: 'Grupos',
                type: 'item',
                icon: 'people',
                url: '/groups',
              } : {},
            access.menu_teams ?
              {
                id: 'teams',
                title: 'Times',
                translate: 'Times',
                type: 'item',
                icon: 'people',
                url: '/teams',
              } : {},

            access.menu_clients ?
              {
                id: 'clients',
                title: 'Clientes',
                translate: 'Clientes',
                type: 'item',
                icon: 'explore',
                url: '/clientes',
              } : {},
            access.menu_agencies ?
              {
                id: 'agencies',
                title: 'Agências',
                translate: 'Agências',
                type: 'item',
                icon: 'disc_full',
                url: '/agencias',
              } : {},

            access.menu_products ?
              {
                id: 'products',
                title: 'Produtos',
                translate: 'Produtos',
                type: 'item',
                icon: 'event',
                url: '/produtos',
              } : {},
            access.menu_squares ?
              {
                id: 'squares',
                title: 'Praças',
                translate: 'Praças',
                type: 'item',
                icon: 'dynamic_feed',
                url: '/pracas',
              } : {},
            access.menu_tags ?
              {
                id: 'tags',
                title: 'Etiquetas',
                translate: 'Etiquetas',
                type: 'item',
                icon: 'flag',
                url: '/tags',
              } : {},
            access.menu_status ?
              {
                id: 'status',
                title: 'Status',
                translate: 'Status',
                type: 'item',
                icon: 'dock',
                url: '/status',
              } : {},
            access.menu_justify ?
              {
                id: 'justify',
                title: 'Justificativa',
                translate: 'Justificativa',
                type: 'item',
                icon: 'dock',
                url: '/justify',
              } : {},

          ]
        } : {},
      access.menu_proposals ?
        {
          id: 'proposals',
          title: 'Negócios',
          translate: 'Negócios',
          type: 'item',
          icon: 'handyman',
          url: '/propostas',
        } : {},
      // access.menu_pi ?
      //   {
      //     id: 'opecs',
      //     title: 'PI/PP',
      //     translate: 'PI/PP',
      //     type: 'item',
      //     icon: 'plumbing',
      //     url: '/pipp',
      //   } : {},
      access.menu_finances ?
        {
          id: 'financeiro',
          title: 'Financeiro',
          translate: 'Financeiro',
          type: 'collapse',
          icon: 'payments',
          children: [
            access.menu_config ?
              {
                id: 'configuration',
                title: 'Configurações',
                translate: 'Configurações',
                type: 'collapse',
                icon: 'archive',
                children: [

                  access.menu_config_params ?
                    {
                      id: 'params',
                      title: 'Parametros',
                      translate: 'Parâmetros',
                      type: 'item',
                      icon: 'support',
                      url: '/params',
                    } : {},
                  access.menu_config_holidays ?
                    {
                      id: 'params',
                      title: 'Feriados',
                      translate: 'Feriados',
                      type: 'item',
                      icon: 'calendar_today',
                      url: '/holidays',
                    } : {},
                  access.menu_config_goals ?
                    {
                      id: 'goals',
                      title: 'Metas',
                      translate: 'Metas',
                      type: 'collapse',
                      icon: 'bookmark',
                      children: [
                        access.menu_config_goals_global ?
                          {
                            id: 'params',
                            title: 'Geral',
                            translate: 'Geral',
                            type: 'item',
                            icon: 'public',
                            url: '/goalsglobal',
                          } : {},
                        access.menu_config_goals_month ?
                          {
                            id: 'params',
                            title: 'Por Meses',
                            translate: 'Por Meses',
                            type: 'item',
                            icon: 'tab',
                            url: '/goalsbymonth',
                          } : {},
                        access.menu_config_goals_team ?
                          {
                            id: 'params',
                            title: 'Por Times',
                            translate: 'Por Times',
                            type: 'item',
                            icon: 'groups',
                            url: '/goalsbyteam',
                          } : {},
                        access.menu_config_goals_vehicle ?
                          {
                            id: 'params',
                            title: 'Por Veículos',
                            translate: 'Por Veículos',
                            type: 'item',
                            icon: 'spatial_tracking',
                            url: '/goalsbyvehicle',
                          } : {},
                      ]
                    } : {},
                  access.menu_config_questions_default ?
                    {
                      id: 'params',
                      title: 'Perguntas Padrão',
                      translate: 'Perguntas Padrão',
                      type: 'item',
                      icon: 'help',
                      url: '/questionsdefault',
                    } : {},
                ],
              } : {},
            access.menu_finances_daily ? {
              id: 'financedaily',
              title: 'Diário',
              translate: 'Diário',
              type: 'collapse',
              icon: 'update',
              children: [
                {
                  id: 'financedaily',
                  title: 'Por Veículo',
                  translate: 'Por Veículo',
                  type: 'item',
                  icon: 'directions_car',
                  url: '/financedailyvehicle',
                },
                {
                  id: 'financedaily',
                  title: 'Por Praça',
                  translate: 'Por Praça',
                  type: 'item',
                  icon: 'dynamic_feed',
                  url: '/financedailysquare',
                },
                {
                  id: 'params',
                  title: 'Por Times',
                  translate: 'Por Times',
                  type: 'item',
                  icon: 'groups',
                  url: '/financedailyteam',
                },
              ]
            } : {},
            access.menu_finances_comission ? {
              id: 'comission',
              title: 'Comissões',
              translate: 'Comissões',
              type: 'collapse',
              icon: 'policy',
              children: [
                access.menu_finances_comission_user ?
                  {
                    id: 'comissionuser',
                    title: 'Usuários',
                    translate: 'Usuários',
                    type: 'item',
                    icon: 'groups',
                    url: '/comissionuser',
                  } : {},
              ]
            } : {}
          ]
        } : {},
      access.menu_report ? {
        id: 'comission',
        title: 'Relatórios',
        translate: 'Relatórios',
        type: 'collapse',
        icon: 'report',
        children: [
          access.menu_export ?
            {
              id: 'reports',
              title: 'Exports Personalizados',
              translate: 'Exports Personalizados',
              type: 'item',
              icon: 'insert_chart_outlined',
              url: '/reports',
            } : {},
          access.menu_looker ?
            {
              id: 'reports',
              title: 'Report Geral',
              translate: 'Report OPEC',
              type: 'item',
              icon: 'all_inbox',
              url: '/lookerstudio',
            } : {},
          access.menu_dashboard ?
            {
              id: 'dashboard',
              title: 'Dashboard',
              translate: 'Dashboard',
              type: 'item',
              icon: 'apps',
              url: '/dashboard',
            } : {},
          user.role == 'admin' ?
            {
              id: 'excel',
              title: 'Planilha Excel',
              translate: 'Planilha Excel',
              type: 'item',
              icon: 'add_to_drive',
              url: '/excel',
            } : {},
          access.menu_checking_report ?
            {
              id: 'checking',
              title: 'Checking',
              translate: 'Checking',
              type: 'item',
              icon: 'check',
              url: '/checkingreport',
            } : {},
          access.menu_daily_report ?
            {
              id: 'daily',
              title: 'Diário',
              translate: 'Diário',
              type: 'item',
              icon: 'update',
              url: '/dailyreport',
            } : {},
          access.menu_templates ?
            {
              id: 'templates',
              title: 'Templates',
              translate: 'Templates',
              type: 'item',
              icon: 'update',
              url: '/templates',
            } : {},
        ]
      } : {},

    ],
  },
];

export default navigationConfig;
