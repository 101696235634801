import CommonForm from "app/components/form/CommonForm";
import React, { useEffect, useState } from "react";
import axios from 'app/utils/AxiosConfig';
import Constants from "app/utils/Constants";
import { LinearProgress } from '@mui/material';
import io from 'socket.io-client';
import { saveAs } from 'file-saver';
import { Typography } from "@material-ui/core";
import moment from "moment";
const socket = io(Constants.HOST);

const TemplateContainer = () => {
    const [values, setValues] = useState([])
    const [users, setUsers] = useState([])
    const [vehicles, setVehicles] = useState([])
    const [squares, setSquares] = useState([])
    const [tags, setTags] = useState([])
    const [status, setStatus] = useState([])
    const [groups, setGroups] = useState([])
    const [clients, setClients] = useState([])
    const [justifies, setJustifies] = useState([])
    const [agencies, setAgencies] = useState([])
    const [progress, setProgress] = useState(0)
    const [timingLeft, setTimingLeft] = useState(0)
    const [statusProgress, setStatusProgress] = useState('')
    const [totalProposals, setTotalProposals] = useState(0)
    useEffect(() => {
        axios.post(
            Constants.APIEndpoints.PROPOSAL + "/getallgeneraldata", {
            entities: ['products', 'status', 'groups', 'justifies', 'agencies', 'users', 'clients', 'vehicles', 'squares', 'tags']
        }).then((res) => {
            setAgencies(res.data.agencies)
            setUsers(res.data.users)
            setClients(res.data.clients)
            setVehicles(res.data.vehicles)
            setSquares(res.data.squares)
            setTags(res.data.tags);
            setStatus(res.data.status)
            setGroups(res.data.groups)
            setJustifies(res.data.justifies)
        })
    }, [])

    useEffect(() => {
        socket.on('building-report', (data) => {
            setProgress(data.progress)
            setTimingLeft(data.timingLeft)
            setStatusProgress(data.status)
            setTotalProposals(data.totalProposals)
        })
    })


    const monthList = [
        { value: 0, label: "Janeiro" },
        { value: 1, label: "Fevereiro" },
        { value: 2, label: "Março" },
        { value: 3, label: "Abril" },
        { value: 4, label: "Maio" },
        { value: 5, label: "Junho" },
        { value: 6, label: "Julho" },
        { value: 7, label: "Agosto" },
        { value: 8, label: "Setembro" },
        { value: 9, label: "Outubro" },
        { value: 10, label: "Novembro" },
        { value: 11, label: "Dezembro" },
    ];
    const fields =
        [
            {
                col: 2,
                name: 'number',
                label: 'Nº PI/PP',
                type: 'text',
            },
            {
                col: 2,
                name: 'tags',
                type: 'multiselect',
                label: 'Etiquetas',
                options: tags.map(k => ({ value: k.id_tags, label: k.name, color: k.color })),
            },
            {
                col: 2,
                type: "multiselect",
                name: "fk_id_status",
                label: "Status",
                options: status.filter(s => s.sector == 'COMERCIAL').map(st => {
                    return {
                        value: st.id_status,
                        label: st.name,
                        color: 'black'
                    }
                }),
            },
            {
                col: 2,
                type: "multiselect",
                name: "fk_id_vehicle",
                label: "Veículo",
                options: vehicles.map(v => {
                    return {
                        value: v.id_vehicle,
                        label: v.fancy_name
                    }
                }),
            },
            {
                col: 2,
                type: "multiselect",
                name: "group",
                label: "Grupo De Mídia",
                options: groups.map(group => ({ value: group.id_groups, label: group.name })),
            },
            {
                col: 2,
                type: "multiselect",
                name: "fk_id_client",
                label: "Cliente",
                options: clients.map(c => {
                    return {
                        value: c.id_client,
                        label: c.fancy_name
                    }
                }),
            },
            {
                col: 2,
                type: "multiselect",
                name: "fk_id_agency",
                label: "Agência",
                options: agencies.map(ag => {
                    return {
                        value: ag.id_agency,
                        label: ag.fancy_name
                    }
                }),
            },
            {
                col: 2,
                type: "text",
                name: "sponsor_vehicle",
                label: "Representante",
            },

            {
                col: 2,
                type: "date",
                name: "dt_start",
                label: "Data de Início",
            },
            {
                col: 2,
                type: "date",
                name: "dt_end",
                label: "Data de Término",
            },
            {
                col: 2,
                type: "multiselect",
                name: "month_placement",
                label: "Mês de Veiculação",
                options: monthList,
            },
            {
                col: 2,
                type: "multiselect",
                name: "fk_id_square",
                label: "Praça de venda",
                options: squares.map(sq => {
                    return {
                        value: sq.id_square,
                        label: sq.uf
                    }
                }),
            },
        ]


    const saveTemplate = async (values) => {
        const token = sessionStorage.getItem('token');
        fetch(Constants.APIEndpoints.VEHICLE + "/savetemplate",
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-Access-Token': token
                },
                body: JSON.stringify({
                    ...values,
                })
            }).then(results => {
                return results.blob()
            }).then(response => {
                saveAs(response, `templates_${moment().format('YYMMDDHHmmss')}.zip`);
                setProgress(0)
                setTimingLeft(0)
                setStatusProgress('')
                setTotalProposals(0)
            })
    }
    return (
        <div>
            <CommonForm
                fields={fields}
                values={values}
                onChangeField={(f, v) => {
                    setValues(prev => ({ ...prev, [f.name]: v }))
                }}
                button={{ label: 'Criar' }}
                onSubmit={saveTemplate}
            />
            <div style={{ width: '96%', marginLeft: '2%' }}>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Typography className="title">Criando {totalProposals} Processos de Checkings.</Typography>
                    <Typography className="title">{progress}%</Typography>
                    <Typography className="title">{statusProgress}</Typography>
                    <Typography className="title">Tempo restante: {parseFloat(timingLeft / 1000).toFixed(2)}s</Typography>
                </div>
                <LinearProgress variant="determinate" value={progress} />
            </div>
        </div>
    )
}

export default TemplateContainer