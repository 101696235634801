import React from 'react'
import { Box, Divider, Grid } from "@material-ui/core"
import moment from "moment-timezone";
import './ProposalView.css'
const ProposalProducts = ({ proposal }) => {
    const products = proposal.products
    return (
        <div>
            <div style={{ marginTop: 60 }}>
                <Box sx={{ flexGrow: 1 }}>
                    <Grid container spacing={1} style={{ backgroundColor: 'white' }} >
                        <Grid item xs={12}>
                            <div className="cardProposal">
                                <div>
                                    <label>PRODUTOS</label>
                                    <table style={{ width: '100%' }}>
                                        <thead>
                                            <tr>
                                                <th style={{ color: "black" }}>Produto</th>
                                                <th style={{ color: "black" }}>Objetivo</th>
                                                <th style={{ color: "black" }}>Preço</th>
                                                <th style={{ color: "black" }}>Qtd. Contratada</th>
                                                <th style={{ color: "black" }}>Negociação </th>
                                                <th style={{ color: "black" }}>Dt. Inicial</th>
                                                <th style={{ color: "black" }}>Dt. Final</th>
                                                <th style={{ color: "black" }}>Dias</th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            {(products || []).map(product => (
                                                <tr key={product.fk_id_product} style={{ color: "black" }}>
                                                    <td className="table_td">{product.name}</td>
                                                    <td className="table_td">{product.objective}</td>
                                                    <td className="table_td">{parseFloat(product.price).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</td>
                                                    <td className="table_td">{product.quantity_hired}</td>
                                                    <td className="table_td">{product.negociation}%</td>
                                                    <td className="table_td">{moment(product.dt_start).format('DD/MM/YYYY')}</td>
                                                    <td className="table_td">{moment(product.dt_end).format('DD/MM/YYYY')}</td>
                                                    <td className="table_td">{product.days}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </Grid>


                    </Grid>
                </Box>
            </div>
        </div >
    )
}

export default ProposalProducts