import React, { useState } from "react";
import CommonHeader from "app/components/table/CommonHeader";
import CommonForm from "app/components/form/CommonForm";
import axios from "app/utils/AxiosConfig";
import Constants from "app/utils/Constants";
import { useResizeDetector } from "react-resize-detector";
import { Typography } from "@mui/material";
import { Divider } from "@material-ui/core";
export default function AccessForm({ values, setPage, getData }) {
  const [valuesForm, setValuesForm] = useState(values);
  const [vehicles, setVehicles] = useState([]);
  const { width, height, ref } = useResizeDetector();

  let fields = [
    {
      col: 12,
      type: "text",
      name: "name",
      label: "Nome",
      required: true,
    },
    {
      col: 12,
      type: "content",
      content: <Typography>MENUS</Typography>,
    },
    {
      col: 2,
      type: "checkbox",
      name: "menu_home",
      label: "Home",
    },
    {
      col: 2,
      type: "checkbox",
      name: "menu_lead_visuplac",
      label: "Leads Visuplac",
    },
    {
      col: 2,
      type: "checkbox",
      name: "menu_calendar",
      label: "Calendário",
    },
    {
      col: 2,
      type: "checkbox",
      name: "menu_dashboard",
      label: "Dashboard",
    },
    {
      col: 2,
      type: "checkbox",
      name: "menu_admin",
      label: "Administrativo",
    },
    {
      col: 2,
      type: "checkbox",
      name: "menu_users",
      label: "Usuários",
    },
    {
      col: 2,
      type: "checkbox",
      name: "menu_mailings",
      label: "Contatos",
    },
    {
      col: 2,
      type: "checkbox",
      name: "menu_tech_report",
      label: "Relatório de Usuários",
    },
    {
      col: 2,
      type: "checkbox",
      name: "menu_status",
      label: "Status",
    },
    {
      col: 2,
      type: "checkbox",
      name: "menu_checking_report",
      label: "Relatório Checking",
    },
    {
      col: 2,
      type: "checkbox",
      name: "menu_justify",
      label: "Justificativa",
    },
    {
      col: 2,
      type: "checkbox",
      name: "menu_access",
      label: "Acceso",
    },
    {
      col: 2,
      type: "checkbox",
      name: "menu_registers",
      label: "Cadastros",
    },
    {
      col: 2,
      type: "checkbox",
      name: "menu_vehicles",
      label: "Veículos",
    },
    {
      col: 2,
      type: "checkbox",
      name: "menu_groups",
      label: "Grupos",
    },
    {
      col: 2,
      type: "checkbox",
      name: "menu_teams",
      label: "Times",
    },
    {
      col: 2,
      type: "checkbox",
      name: "menu_clients",
      label: "Clientes",
    },
    {
      col: 2,
      type: "checkbox",
      name: "menu_agencies",
      label: "Agências",
    },
    {
      col: 2,
      type: "checkbox",
      name: "menu_products",
      label: "Produtos",
    },
    {
      col: 2,
      type: "checkbox",
      name: "menu_squares",
      label: "Praças",
    },
    {
      col: 2,
      type: "checkbox",
      name: "menu_tags",
      label: "Etiquetas",
    },
    {
      col: 2,
      type: "checkbox",
      name: "menu_proposals",
      label: "Negócios",
    },
    {
      col: 2,
      type: "checkbox",
      name: "menu_pi",
      label: "PI/PP",
    },
    {
      col: 2,
      type: "checkbox",
      name: "menu_finances",
      label: "Financeiro",
    },
    {
      col: 2,
      type: "checkbox",
      name: "menu_finances_daily",
      label: "Financeiro - Diário",
    },
    {
      col: 2,
      type: "checkbox",
      name: "menu_finances_daily_vehicle",
      label: "Financeiro - Diário - Por Veículo",
    },
    {
      col: 2,
      type: "checkbox",
      name: "menu_finances_daily_square",
      label: "Financeiro - Diário - Por Praça",
    },
    {
      col: 2,
      type: "checkbox",
      name: "menu_finances_daily_team",
      label: "Financeiro - Diário - Por Time",
    },
    {
      col: 2,
      type: "checkbox",
      name: "menu_finances_comission",
      label: "Financeiro - Comissões",
    },
    {
      col: 2,
      type: "checkbox",
      name: "menu_finances_comission_user",
      label: "Financeiro - Comissões - Por usuário",
    },
    {
      col: 2,
      type: "checkbox",
      name: "menu_config",
      label: "Configurações",
    },
    {
      col: 2,
      type: "checkbox",
      name: "menu_config_params",
      label: "Parâmetros",
    },
    {
      col: 2,
      type: "checkbox",
      name: "menu_config_questions_default",
      label: "Perguntas Padrões",
    },
    {
      col: 2,
      type: "checkbox",
      name: "menu_config_holidays",
      label: "Feriados",
    },
    {
      col: 2,
      type: "checkbox",
      name: "menu_config_goals",
      label: "Metas",
    },
    {
      col: 2,
      type: "checkbox",
      name: "menu_config_goals_global",
      label: "Metas Globais",
    },
    {
      col: 2,
      type: "checkbox",
      name: "menu_config_goals_month",
      label: "Metas Mensais",
    },
    {
      col: 2,
      type: "checkbox",
      name: "menu_config_goals_team",
      label: "Metas por Times",
    },
    {
      col: 2,
      type: "checkbox",
      name: "menu_config_vehicles",
      label: "Metas por Veículos",
    },
    {
      col: 2,
      type: "checkbox",
      name: "menu_export",
      label: "Export Personalizado",
    },
    {
      col: 2,
      type: "checkbox",
      name: "menu_looker",
      label: "Report OPEC",
    },
    {
      col: 2,
      type: "checkbox",
      name: "menu_crm",
      label: "CRM",
    },
    {
      col: 2,
      type: "checkbox",
      name: "menu_report",
      label: "Relatórios",
    },
    {
      col: 2,
      type: "checkbox",
      name: "menu_daily_report",
      label: "Relatórios Diários",
    },
    {
      col: 2,
      type: "checkbox",
      name: "menu_logs",
      label: "Logs",
    },
    {
      col: 2,
      type: "checkbox",
      name: "menu_own_dash",
      label: "Sua Dashboard",
    },
    {
      col: 2,
      type: "checkbox",
      name: "menu_qa",
      label: "Q&A",
    },
    {
      col: 2,
      type: "checkbox",
      name: "menu_templates",
      label: "Templates",
    },
    {
      col: 12,
      type: "content",
      content: <Divider />,
    },
    {
      col: 12,
      type: "content",
      content: <Typography>PERMISSÕES</Typography>,
    },
    {
      col: 12,
      type: "content",
      content: <Typography>USUÁRIOS</Typography>,
    },
    {
      col: 2,
      type: "checkbox",
      name: "user_create",
      label: "Criar",
    },
    {
      col: 2,
      type: "checkbox",
      name: "user_read",
      label: "Ver",
    },
    {
      col: 2,
      type: "checkbox",
      name: "user_update",
      label: "Editar",
    },
    {
      col: 2,
      type: "checkbox",
      name: "user_delete",
      label: "Excluir",
    },
    {
      col: 2,
      type: "checkbox",
      name: "user_changepass",
      label: "Alterar Senha",
    },
    {
      col: 12,
      type: "content",
      content: <Typography>CONTATOS</Typography>,
    },
    {
      col: 2,
      type: "checkbox",
      name: "mailing_create",
      label: "Criar",
    },
    {
      col: 2,
      type: "checkbox",
      name: "mailing_read",
      label: "Ver",
    },
    {
      col: 2,
      type: "checkbox",
      name: "mailing_update",
      label: "Editar",
    },
    {
      col: 2,
      type: "checkbox",
      name: "mailing_delete",
      label: "Excluir",
    },
    {
      col: 12,
      type: "content",
      content: <Typography>STATUS</Typography>,
    },
    {
      col: 2,
      type: "checkbox",
      name: "status_create",
      label: "Criar",
    },
    {
      col: 2,
      type: "checkbox",
      name: "status_read",
      label: "Ver",
    },
    {
      col: 2,
      type: "checkbox",
      name: "status_update",
      label: "Editar",
    },
    {
      col: 2,
      type: "checkbox",
      name: "status_delete",
      label: "Excluir",
    },
    {
      col: 12,
      type: "content",
      content: <Typography>JUSTIFICATIVA</Typography>,
    },
    {
      col: 2,
      type: "checkbox",
      name: "justify_create",
      label: "Criar",
    },
    {
      col: 2,
      type: "checkbox",
      name: "justify_update",
      label: "Editar",
    },
    {
      col: 2,
      type: "checkbox",
      name: "justify_delete",
      label: "Excluir",
    },
    {
      col: 12,
      type: "content",
      content: <Typography>CRM</Typography>,
    },
    {
      col: 2,
      type: "checkbox",
      name: "crm_create_funnel",
      label: "Criar Funil",
    },
    {
      col: 12,
      type: "content",
      content: <Typography>VEÍCULO</Typography>,
    },
    {
      col: 2,
      type: "checkbox",
      name: "vehicle_create",
      label: "Criar",
    },
    {
      col: 2,
      type: "checkbox",
      name: "vehicle_activate",
      label: "Ativar/Desativar",
    },
    {
      col: 2,
      type: "checkbox",
      name: "vehicle_read",
      label: "Ver",
    },
    {
      col: 2,
      type: "checkbox",
      name: "vehicle_update",
      label: "Editar",
    },
    {
      col: 2,
      type: "checkbox",
      name: "vehicle_delete",
      label: "Excluir",
    },
    {
      col: 12,
      type: "content",
      content: <Typography>GRUPOS</Typography>,
    },
    {
      col: 2,
      type: "checkbox",
      name: "group_create",
      label: "Criar",
    },
    {
      col: 2,
      type: "checkbox",
      name: "group_read",
      label: "Ver",
    },
    {
      col: 2,
      type: "checkbox",
      name: "group_update",
      label: "Editar",
    },
    {
      col: 2,
      type: "checkbox",
      name: "group_delete",
      label: "Excluir",
    },
    {
      col: 12,
      type: "content",
      content: <Typography>TIMES</Typography>,
    },
    {
      col: 2,
      type: "checkbox",
      name: "team_create",
      label: "Criar",
    },
    {
      col: 2,
      type: "checkbox",
      name: "team_read",
      label: "Ver",
    },
    {
      col: 2,
      type: "checkbox",
      name: "team_update",
      label: "Editar",
    },
    {
      col: 2,
      type: "checkbox",
      name: "team_delete",
      label: "Excluir",
    },
    {
      col: 2,
      type: "checkbox",
      name: "team_adduser",
      label: "Adicionar Usuário",
    },
    {
      col: 12,
      type: "content",
      content: <Typography>CLIENTES</Typography>,
    },
    {
      col: 2,
      type: "checkbox",
      name: "client_create",
      label: "Criar",
    },
    {
      col: 2,
      type: "checkbox",
      name: "client_read",
      label: "Ver",
    },
    {
      col: 2,
      type: "checkbox",
      name: "client_update",
      label: "Editar",
    },
    {
      col: 2,
      type: "checkbox",
      name: "client_delete",
      label: "Excluir",
    },
    {
      col: 12,
      type: "content",
      content: <Typography>AGÊNCIAS</Typography>,
    },
    {
      col: 2,
      type: "checkbox",
      name: "agency_create",
      label: "Criar",
    },
    {
      col: 2,
      type: "checkbox",
      name: "agency_read",
      label: "Ver",
    },
    {
      col: 2,
      type: "checkbox",
      name: "agency_update",
      label: "Editar",
    },
    {
      col: 2,
      type: "checkbox",
      name: "agency_delete",
      label: "Excluir",
    },
    {
      col: 12,
      type: "content",
      content: <Typography>PRODUTOS</Typography>,
    },
    {
      col: 2,
      type: "checkbox",
      name: "product_create",
      label: "Criar",
    },
    {
      col: 2,
      type: "checkbox",
      name: "product_read",
      label: "Ver",
    },
    {
      col: 2,
      type: "checkbox",
      name: "product_update",
      label: "Editar",
    },
    {
      col: 2,
      type: "checkbox",
      name: "product_delete",
      label: "Excluir",
    },
    {
      col: 12,
      type: "content",
      content: <Typography>PRAÇAS</Typography>,
    },
    {
      col: 2,
      type: "checkbox",
      name: "square_create",
      label: "Criar",
    },
    {
      col: 2,
      type: "checkbox",
      name: "square_read",
      label: "Ver",
    },
    {
      col: 2,
      type: "checkbox",
      name: "square_update",
      label: "Editar",
    },
    {
      col: 2,
      type: "checkbox",
      name: "square_delete",
      label: "Excluir",
    },
    {
      col: 12,
      type: "content",
      content: <Typography>ETIQUETAS</Typography>,
    },
    {
      col: 2,
      type: "checkbox",
      name: "tag_create",
      label: "Criar",
    },
    {
      col: 2,
      type: "checkbox",
      name: "tag_read",
      label: "Ver",
    },
    {
      col: 2,
      type: "checkbox",
      name: "tag_update",
      label: "Editar",
    },
    {
      col: 2,
      type: "checkbox",
      name: "tag_delete",
      label: "Excluir",
    },
    {
      col: 12,
      type: "content",
      content: <Typography>NEGÓCIOS</Typography>,
    },
    {
      col: 2,
      type: "checkbox",
      name: "proposal_create",
      label: "Criar",
    },
    {
      col: 2,
      type: "checkbox",
      name: "proposal_read",
      label: "Ver",
    },
    {
      col: 2,
      type: "checkbox",
      name: "proposal_update",
      label: "Editar",
    },
    {
      col: 2,
      type: "checkbox",
      name: "proposal_delete",
      label: "Excluir",
    },
    {
      col: 2,
      type: "checkbox",
      name: "proposal_finances",
      label: "Finaceiro",
    },
    {
      col: 2,
      type: "checkbox",
      name: "proposal_change_date_status",
      label: "Alterar Data de Troca de Status",
    },
    {
      col: 2,
      type: "checkbox",
      name: "proposal_show_products_list",
      label: "Visualizar produtos na lista de negócios",
    },

    {
      col: 12,
      type: "content",
      content: <Typography>PI/PP</Typography>,
    },
    {
      col: 2,
      type: "checkbox",
      name: "pi_create",
      label: "Criar",
    },
    {
      col: 2,
      type: "checkbox",
      name: "pi_read",
      label: "Ver",
    },
    {
      col: 2,
      type: "checkbox",
      name: "pi_update",
      label: "Editar",
    },
    {
      col: 2,
      type: "checkbox",
      name: "pi_delete",
      label: "Excluir",
    },
    {
      col: 12,
      type: "content",
      content: <Typography>PARÂMETROS</Typography>,
    },
    {
      col: 2,
      type: "checkbox",
      name: "config_params",
      label: "Alterar",
    },
    {
      col: 12,
      type: "content",
      content: <Typography>FERIADOS</Typography>,
    },
    {
      col: 2,
      type: "checkbox",
      name: "config_holiday_create",
      label: "Criar",
    },
    {
      col: 2,
      type: "checkbox",
      name: "config_holiday_update",
      label: "Editar",
    },
    {
      col: 2,
      type: "checkbox",
      name: "config_holiday_delete",
      label: "Excluir",
    },
    {
      col: 12,
      type: "content",
      content: <Typography>PERGUNTAS PADRÕES</Typography>,
    },
    {
      col: 2,
      type: "checkbox",
      name: "config_default_questions_create",
      label: "Criar",
    },
    {
      col: 2,
      type: "checkbox",
      name: "config_default_questions_update",
      label: "Editar",
    },
    {
      col: 2,
      type: "checkbox",
      name: "config_default_questions_delete",
      label: "Excluir",
    },
    {
      col: 12,
      type: "content",
      content: <Typography>META GLOBAL</Typography>,
    },
    {
      col: 2,
      type: "checkbox",
      name: "goal_global_create",
      label: "Criar",
    },
    {
      col: 2,
      type: "checkbox",
      name: "goal_global_update",
      label: "Editar",
    },
    {
      col: 2,
      type: "checkbox",
      name: "goal_global_delete",
      label: "Excluir",
    },
    {
      col: 12,
      type: "content",
      content: <Typography>META MENSAL</Typography>,
    },
    {
      col: 2,
      type: "checkbox",
      name: "goal_month_create",
      label: "Criar",
    },
    {
      col: 2,
      type: "checkbox",
      name: "goal_month_update",
      label: "Editar",
    },
    {
      col: 2,
      type: "checkbox",
      name: "goal_month_delete",
      label: "Excluir",
    },
    {
      col: 12,
      type: "content",
      content: <Typography>META POR TIME</Typography>,
    },
    {
      col: 2,
      type: "checkbox",
      name: "goal_team_create",
      label: "Criar",
    },
    {
      col: 2,
      type: "checkbox",
      name: "goal_team_update",
      label: "Editar",
    },
    {
      col: 2,
      type: "checkbox",
      name: "goal_team_delete",
      label: "Excluir",
    },
    {
      col: 12,
      type: "content",
      content: <Typography>META POR VEÍCULO</Typography>,
    },
    {
      col: 2,
      type: "checkbox",
      name: "goal_vehicle_create",
      label: "Criar",
    },
    {
      col: 2,
      type: "checkbox",
      name: "goal_vehicle_update",
      label: "Editar",
    },
    {
      col: 2,
      type: "checkbox",
      name: "goal_vehicle_delete",
      label: "Excluir",
    },
  ];

  const onSubmit = () => {
    axios
      .post(Constants.APIEndpoints.ACCESS + (values.id_access ? "/updateaccess" : "/createaccess"), valuesForm)
      .then((res) => {
        setPage("list");
        getData();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div ref={ref}>
      <CommonHeader title="Criar Acesso" width={width} onBack={() => setPage("list")} />
      <CommonForm
        values={valuesForm}
        hasHeader={true}
        fields={fields}
        onChangeField={(f, v) => {
          values[f.name] = v;
          setValuesForm(values);
        }}
        onSubmit={onSubmit}
      />
    </div>
  );
}
