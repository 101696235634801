import React, { useState, useEffect } from 'react';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { Typography, Divider } from '@material-ui/core';
import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import moment from 'moment-timezone'
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Constants from "app/utils/Constants";
import axios from "app/utils/AxiosConfig";
import Store from 'app/utils/Store'
import CommonForm from 'app/components/form/CommonForm';
let logged_user = Store.USER
const access = logged_user && logged_user.access ? logged_user.access : {}
const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: 'white',
        width: 520,
        padding: 15
    },
}));


function CustomFiltersProposals({
    open,
    onClose,
    mailing,
    onEdit,
    onRemove,
    agencies,
    clients,
    vehicles,
    squares,
    products,
    status,
    users,
    tags,
    justifies,
    getData,
    idCustomFieldSelected,
    setIdCustomFieldSelected,
    getCustomFilters,
    customFilters,
    groups,
    selectedFilter,
    setSelectedFilter
}) {
    const classes = useStyles();
    const [openCreate, setOpenCreate] = useState(false)

    const monthList = [
        { value: 0, label: "Janeiro" },
        { value: 1, label: "Fevereiro" },
        { value: 2, label: "Março" },
        { value: 3, label: "Abril" },
        { value: 4, label: "Maio" },
        { value: 5, label: "Junho" },
        { value: 6, label: "Julho" },
        { value: 7, label: "Agosto" },
        { value: 8, label: "Setembro" },
        { value: 9, label: "Outubro" },
        { value: 10, label: "Novembro" },
        { value: 11, label: "Dezembro" },
    ];

    const saveCustomFilter = (values) => {

        axios.post(Constants.APIEndpoints.PROPOSAL + (selectedFilter.id_custom_filters_proposals ? '/updatecustomfilters' : '/savecustomfilters'), values).then(res => {
            setOpenCreate(false)
            getCustomFilters()
        })
    }

    const loadProposals = (filter) => {
        setIdCustomFieldSelected(filter.id_custom_filters_proposals)
        setSelectedFilter({ id_custom_filters_proposals: filter.id_custom_filters_proposals, ...JSON.parse(filter.filters) })
        localStorage.setItem('filters', JSON.stringify({ id_custom_filters_proposals: filter.id_custom_filters_proposals, ...JSON.parse(filter.filters) }))
        getData({ custom: JSON.parse(filter.filters) })
    }

    const editFilter = (filter) => {
        setSelectedFilter({ id_custom_filters_proposals: filter.id_custom_filters_proposals, ...JSON.parse(filter.filters) })
        setOpenCreate(true)
    }

    const deleteFilter = (filter) => {
        axios.post(Constants.APIEndpoints.PROPOSAL + '/deletecustomfilter', filter).then(res => {
            getCustomFilters()
        })
    }

    return (
        <SwipeableDrawer
            classes={{ paper: clsx(classes.root) }}
            open={open}
            anchor="right"
            onOpen={(ev) => { }}
            onClose={onClose}
            disableSwipeToOpen
        >
            <div className="flex flex-col space-y-32">
                <Typography
                    style={{ textAlign: 'center', fontSize: 20, fontWeight: 500 }}>SEUS FILTROS PERSONALIZADOS</Typography>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <button className='buttonChangeViewMode'
                        onClick={() => {
                            setSelectedFilter({})
                            setOpenCreate(true)
                        }}
                        style={{
                            color: 'white', backgroundColor: '#252f3e', maxWidth: 150
                            , borderRadius: 30, margin: 0, marginTop: 10
                        }}>Criar Novo
                    </button>
                    <button className='buttonChangeViewMode'
                        onClick={() => {
                            setSelectedFilter({})
                            setIdCustomFieldSelected(0)
                            localStorage.removeItem('filters')
                            getData({ custom: {} })
                        }}
                        style={{
                            color: 'white', backgroundColor: '#252f3e', maxWidth: 150
                            , borderRadius: 30, margin: 0, marginTop: 10
                        }}>Limpar Filtros
                    </button>
                </div>
                <Divider
                    style={{ marginTop: 20 }}
                />
                {openCreate && (
                    <CommonForm
                        values={selectedFilter}
                        onChangeField={(f, v) => {
                            const newFilter = { ...selectedFilter }
                            newFilter[f.name] = v
                            setSelectedFilter(newFilter)
                        }}
                        fields={[
                            {
                                col: 12,
                                name: 'name',
                                label: 'Nome do Filtro',
                                type: 'text',
                                required: true
                            },
                            {
                                col: 12,
                                type: 'content',
                                content: (
                                    <Divider
                                        style={{ marginTop: 20 }}
                                    />
                                )
                            },
                            {
                                col: 12,
                                name: 'number',
                                label: 'Nº PI/PP',
                                type: 'text',
                                nullable: true,
                            },
                            {
                                col: 12,
                                name: 'tags',
                                type: 'multiselect',
                                label: 'Etiquetas',
                                options: tags.map(k => ({ value: k.id_tags, label: k.name, color: k.color })),
                                nullable: true,
                            },
                            {
                                col: 12,
                                type: "multiselect",
                                name: "fk_id_status",
                                label: "Status",
                                options: status.filter(s => s.sector == 'COMERCIAL').map(st => {
                                    return {
                                        value: st.id_status,
                                        label: st.name,
                                        color: 'black'
                                    }
                                }),
                                nullable: true,
                            },
                            {
                                col: 12,
                                type: "multiselect",
                                name: "fk_id_substatus",
                                label: "Substatus",
                                options: status.filter(s => s.sector == 'COMERCIAL').map(st => {
                                    return {
                                        value: st.id_status,
                                        label: st.name,
                                        color: 'black'
                                    }
                                }),
                                nullable: true,
                            },
                            {
                                col: 12,
                                type: "multiselect",
                                name: "fk_id_vehicle",
                                label: "Veículo",
                                options: vehicles.map(v => {
                                    return {
                                        value: v.id_vehicle,
                                        label: v.fancy_name
                                    }
                                }),
                                nullable: true,
                            },
                            {
                                col: 12,
                                type: "multiselect",
                                name: "group",
                                label: "Grupo De Mídia",
                                options: groups.map(group => ({ value: group.id_groups, label: group.name })),
                                nullable: true,
                            },
                            {
                                col: 12,
                                type: "multiselect",
                                name: "fk_id_client",
                                label: "Cliente",
                                options: clients.map(c => {
                                    return {
                                        value: c.id_client,
                                        label: c.fancy_name
                                    }
                                }),
                                nullable: true,
                            },
                            {
                                col: 12,
                                type: "multiselect",
                                name: "fk_id_agency",
                                label: "Agência",
                                options: agencies.map(ag => {
                                    return {
                                        value: ag.id_agency,
                                        label: ag.fancy_name
                                    }
                                }),
                                nullable: true,
                            },
                            {
                                col: 12,
                                type: "text",
                                name: "sponsor_vehicle",
                                label: "Representante",
                                nullable: true,
                            },
                            {
                                col: 12,
                                type: "multiselect",
                                name: "fk_id_responsable",
                                label: "Executivo",
                                options: users.filter(u => u.fk_id_role == 'executive').map(v => {
                                    return {
                                        value: v.id_user,
                                        label: v.name
                                    }
                                }),
                                nullable: true,
                            },
                            {
                                col: 6,
                                type: "date",
                                name: "dt_start",
                                label: "Data de Início",
                                nullable: true,
                            },
                            {
                                col: 6,
                                type: "date",
                                name: "dt_end",
                                label: "Data de Término",
                                nullable: true,
                            },
                            {
                                col: 6,
                                type: "date",
                                name: "dt_emission_start",
                                label: "Data de Ganho - Início",
                                nullable: true,
                            },
                            {
                                col: 6,
                                type: "date",
                                name: "dt_emission_end",
                                label: "Data de Ganho - Término",
                                nullable: true,
                            },
                            {
                                col: 12,
                                type: "multiselect",
                                name: "month_sell",
                                label: "Mês de venda",
                                options: monthList,
                                nullable: true,
                            },
                            {
                                col: 12,
                                type: "multiselect",
                                name: "month_placement",
                                label: "Mês de Veiculação",
                                options: monthList,
                                nullable: true,
                            },
                            {
                                col: 12,
                                type: "multiselect",
                                name: "fk_id_square",
                                label: "Praça de venda",
                                options: squares.map(sq => {
                                    return {
                                        value: sq.id_square,
                                        label: sq.uf
                                    }
                                }),
                                nullable: true,
                            },
                            {
                                col: 12,
                                type: "text",
                                name: "nf",
                                label: "Nº NF",
                                nullable: true,
                            },
                            {
                                col: 12,
                                type: "multiselect",
                                label: 'Justificativa',
                                name: 'fk_id_justify',
                                options: justifies.filter(j => j.type_justify == 1).map(justify => ({ value: justify.id_justify, label: justify.name })),
                                nullable: true,
                            },
                            {
                                col: 12,
                                type: "text",
                                name: "campaign",
                                label: "Campanha",
                                nullable: true,
                            },
                            {
                                col: 6,
                                type: "date",
                                name: "dt_received_vehicle_start",
                                label: "Data de Recebimento do Veículo - Início",
                                nullable: true,
                            },
                            {
                                col: 6,
                                type: "date",
                                name: "dt_received_vehicle_end",
                                label: "Data de Recebimento do Veículo - Término",
                                nullable: true,
                            },
                            {
                                col: 6,
                                type: "date",
                                name: "dt_received_mosaic_start",
                                label: "Data de Recebimento do Representante - Início",
                                nullable: true,
                            },
                            {
                                col: 6,
                                type: "date",
                                name: "dt_received_mosaic_end",
                                label: "Data de Recebimento do Representante - Término",
                                nullable: true,
                            },
                            {
                                col: 6,
                                type: "date",
                                name: "dt_received_nf_start",
                                label: "Data de Vencimento da NF - Início",
                                nullable: true,
                            }, {
                                col: 6,
                                type: "date",
                                name: "dt_received_nf_end",
                                label: "Data de Vencimento da NF - Término",
                                nullable: true,
                            },

                        ]}
                        onSubmit={saveCustomFilter}
                    />
                )}
                {(customFilters || []).map(customFilter => (
                    <div className="funnelDiv"
                        style={{
                            backgroundColor: customFilter.id_custom_filters_proposals == idCustomFieldSelected ? '#1b2330' : 'white',
                            color: customFilter.id_custom_filters_proposals == idCustomFieldSelected ? 'white' : 'black'
                        }}
                    >

                        <Typography
                            style={{ width: '75%', height: '80%' }}
                            onClick={() => loadProposals(customFilter)}
                        >{customFilter.name}</Typography>
                        <div>
                            <IconButton>
                                <Icon
                                    onClick={() => editFilter(customFilter)}
                                    style={{ color: customFilter.id_custom_filters_proposals == idCustomFieldSelected ? 'white' : 'black' }}
                                >
                                    edit
                                </Icon>
                            </IconButton>
                            <IconButton>
                                <Icon
                                    style={{ color: customFilter.id_custom_filters_proposals == idCustomFieldSelected ? 'white' : 'black' }}
                                    onClick={() => deleteFilter(customFilter)}
                                >
                                    delete
                                </Icon>
                            </IconButton>
                        </div>
                    </div>
                ))}
            </div>

        </SwipeableDrawer >
    );
}

export default CustomFiltersProposals;
