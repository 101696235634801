import React, { useEffect, useState } from "react";
import CommonHeader from "app/components/table/CommonHeader";
import CommonForm from "app/components/form/CommonForm";
import axios from 'app/utils/AxiosConfig';
import Constants from "app/utils/Constants";
import { useResizeDetector } from 'react-resize-detector';
import moment from 'moment'
function VehicleForm({ values, setPage, getData, users, squares, groups }) {
  if (values && values.users_vehicle) {
    values.users = values.users_vehicle
  }
  if (values && values.time) {
    values.time = values.time ? moment(values.time).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD')
  }
  if (values && values.comission_mosaic) {
    values.comission_mosaic = values.comission_mosaic.toString()
  }
  if (values && values.transfer_value) {
    values.transfer_value = values.transfer_value.toString()
  }
  if (values && values.taxes) {
    values.taxes = values.taxes.toString()
  }

  useEffect(() => {
    getFiles()
  }, [values.id_vehicle])
  const [valuesForm, setValuesForm] = useState(values)
  const { width, ref } = useResizeDetector();
  const [files, setFiles] = useState([])
  const [filesToRemove, setFilesToRemove] = useState([])
  const getFiles = () => {
    if (values.id_vehicle) {
      axios.get(Constants.APIEndpoints.VEHICLE + '/getvehiclefiles/' + values.id_vehicle).then(res => {
        setFiles(res.data)
      })
    }
  }

  const fields = [
    {
      col: 12,
      type: "text",
      name: "fancy_name",
      label: "Nome Fantasia",
      required: true
    },
    {
      col: 6,
      type: "text",
      name: "company_name",
      label: "Razão Social",
      required: true
    },
    {
      col: 6,
      type: "text",
      name: "cnpj",
      label: "CNPJ",
      format: '##.###.###/#####-##',

    },

    {
      col: 4,
      type: "text",
      name: "contat",
      label: "Contato",
    },
    {
      col: 4,
      type: "text",
      name: "phone",
      label: "Telefone",
    },

    {
      col: 4,
      type: "text",
      name: "email",
      label: "Email",
    },
    {
      col: 6,
      type: "text",
      name: "address",
      label: "Endereço",
    },
    {
      col: 2,
      type: "text",
      name: "division",
      label: "Divisão",
    },
    {
      col: 4,
      type: "select",
      name: "wallet",
      label: "Carteira",
      options: groups.map(v => {
        return {
          value: v.id_groups,
          label: v.name
        }
      }),
    },
    {
      col: 4,
      type: "select",
      name: "fk_id_square",
      label: "Praça",
      options: squares.map(v => {
        return {
          value: v.id_square,
          label: v.uf
        }
      }),

    },
    {
      col: 4,
      type: "select",
      name: "fk_id_group",
      label: "Grupo",
      options: groups.map(v => {
        return {
          value: v.id_groups,
          label: v.name
        }
      }),
    },
    {
      col: 4,
      label: "Representante",
      name: "sponsor",
      type: "text",
    },
    {
      col: 4,
      label: "Comissão do Representante",
      name: "comission_mosaic",
      type: "number",
    },
    {
      col: 4,
      label: "Impostos",
      name: "taxes",
      type: "number",
    },
    {
      col: 4,
      label: "Valor de Repasse",
      name: "transfer_value",
      type: "number",
    },
    {
      col: 12,
      label: 'Usuários',
      name: 'users',
      type: 'listCheckbox',
      options: users.map(u => ({ value: u.id_user, label: u.name }))
    },
    {
      col: 6,
      label: "Prazo",
      name: "time",
      type: "date",
    }, {
      col: 6,
      label: "Template",
      name: "template",
      type: "file",
    },
  ];
  const onSubmit = () => {
    valuesForm.templateRemove = filesToRemove
    axios.post(Constants.APIEndpoints.VEHICLE + (values.id_vehicle ? '/updateVehicle' : '/createVehicle'), valuesForm).then(() => {
      setPage('list')
      getData();
    }).catch(error => {
      console.log(error)
    })
  }

  const removeFile = (field, file) => {
    setFilesToRemove(prev => [...prev, file])
    setFiles(prev => ({ ...prev.filter(v => v.filepath != file.filepath) }))
  }

  return (
    <div ref={ref}>
      <CommonHeader title="Criar Veículo"
        width={width}
        onBack={() => setPage('list')} />
      <CommonForm
        values={{ ...valuesForm, template: files }}
        hasHeader={true}
        fields={fields}
        onChangeField={(f, v) => {
          console.log('fff', f.name)
          valuesForm[f.name] = v;
          setValuesForm(prev => ({ ...prev, [f.name]: v }))
        }}
        removeFile={removeFile}
        onSubmit={onSubmit}
      />

    </div>
  );
}

export default VehicleForm;
