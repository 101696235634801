import React, { useState, useEffect } from "react";
import CommonHeader from "app/components/table/CommonHeader";
import CommonForm from "app/components/form/CommonForm";
import axios from 'app/utils/AxiosConfig';
import Constants from "app/utils/Constants";
import moment from 'moment-timezone'
import { useResizeDetector } from 'react-resize-detector';

function UserForm({ values, setPage, getData }) {

  if (values) {
    values.dt_birthday = values.dt_birthday ? moment(values.dt_birthday).format('YYYY-MM-DD') : ''
    //values.profile_pic = []

  }
  const [valuesForm, setValuesForm] = useState(values)
  const [vehicles, setVehicles] = useState([])
  const [sponsors, setSponsors] = useState([])
  const { width, height, ref } = useResizeDetector();
  const [access, setAccess] = useState([])

  useEffect(() => {
    axios
      .get(
        Constants.APIEndpoints.ACCESS + "/getallaccess")
      .then((res) => {
        setAccess(res.data)
      })
    axios.post(
      Constants.APIEndpoints.PROPOSAL + "/getallgeneraldata", {
      entities: ['vehicles', 'sponsors']
    }).then(res => {
      setVehicles(res.data.vehicles)
      setSponsors(res.data.sponsors)
    })
  }, [])

  let fields = [
    {
      col: 12,
      type: "text",
      name: "name",
      label: "Nome",
      required: true

    },
    {
      col: 6,
      type: "text",
      name: "email",
      label: "Email",
      required: true
    },
    {
      col: 6,
      type: "password",
      name: "password",
      label: "Senha",
      required: true,
      visible: values.id_user ? false : true
    },
    {
      col: 4,
      type: "text",
      name: "phone",
      label: "Telefone",
    },
    {
      col: 4,
      type: "text",
      name: "place",
      label: "Local",
    },

    {
      col: 4,
      type: "date",
      name: "dt_birthday",
      label: "Data de Nascimento",
    },
    {
      col: 9,
      type: 'multiselect',
      options: sponsors.map(s => ({ value: s.sponsor, label: s.sponsor })),
      label: 'Adicionar por representante',
      name: 'sponsors'
    },
    {
      col: 3,
      type: 'checkbox',
      label: 'Permissão para alterar propostas para qualquer status',
      name: 'allow_change_any_status'
    },
    {
      col: 12,
      type: "listCheckbox",
      name: "fk_id_vehicle",
      label: "Veículos",
      options: vehicles.map(v => ({ value: v.id_vehicle, label: v.fancy_name }))
    },
    {
      col: 6,
      type: "select",
      name: "fk_id_role",
      label: "Regra",
      options: [
        { value: "admin", label: "Admin" },
        { value: "checking", label: "Checking" },
        { value: "comercial", label: "Comercial" },
        { value: "financeiro", label: "Financeiro" },
        { value: "mailing", label: "Mailing" },
        { value: "opec", label: "Opec" },
        { value: "subadmin", label: "Subadmin" },
        { value: "opecadmin", label: "Opec Admin" },
        { value: "executive", label: "Executivo" },

      ],
    },

    {
      col: 6,
      type: "select",
      name: "fk_id_access",
      label: "Acesso",
      options: access.map(a => ({ value: a.id_access, label: a.name })),
      required: true
    },


    {
      col: 1,
      label: "Ativo",
      name: "active",
      type: "checkbox",
    },

    {
      type: 'image',
      name: 'profile_pic',
      src: valuesForm?.profile_pic?.data ? valuesForm.profile_pic.data : valuesForm?.profile_pic ? valuesForm.profile_pic : ''

    },
  ];
  const onSubmit = () => {
    axios.post(Constants.APIEndpoints.USER + (values.id_user ? '/updateUser' : '/createUser'), valuesForm).then(res => {
      setPage('list')
      getData();
    }).catch(error => {
      console.log(error)
    })


  }
  return (
    <div ref={ref}>
      <CommonHeader title="Criar Usuário"
        width={width}
        onBack={() => setPage('list')} />
      <CommonForm
        values={valuesForm}
        fields={fields}
        hasHeader={true}
        onChangeField={(f, v) => {
          let v_ = { ...valuesForm }
          v_[f.name] = v;
          setValuesForm(v_)
        }}
        onSubmit={onSubmit}
      />

    </div>
  );
}

export default UserForm;
