import CommonForm from "app/components/form/CommonForm";
import React, { useState, useEffect } from 'react'
import axios from 'app/utils/AxiosConfig';
import Constants from "app/utils/Constants";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import './Dashboard.css'
import moment from "moment-timezone";
import Chart from 'react-apexcharts';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import ChartData from './ChartsData'
import Last10Leads from "./Last10Leads";
import Last10Proposals from './Last10Proposals'
import FiltersDash from "./sidebars/FiltersDash";
import SaveFiltersDash from "./sidebars/SaveFiltersDash";
import ListGeneralDashes from "./sidebars/ListGeneralDashes";
import FuseSvgIcon from "@fuse/core/FuseSvgIcon";
import { Autorenew } from "@material-ui/icons";
import ListDataDashboard from "./ListDataDashboard";
import { saveAs } from 'file-saver';
import Store from "app/utils/Store";
import ConfirmDialog from "app/components/dialog/ConfirmDialog";

const loggedUser = Store.USER

export default function DashboardGeneral() {

    const [data, setData] = useState([])
    const [values, setValues] = useState([]);

    const [dateStart, setDateStart] = useState(moment().format('YYYY-MM-01'))
    const [dateEnd, setDateEnd] = useState(moment().format('YYYY-MM-DD'))
    const [statusSelected, setStatusSelected] = useState(0)
    const [sponsor, setSponsor] = useState('')


    const [vehicles, setVehicles] = useState([])
    const [users, setUsers] = useState([])
    const [groups, setGroups] = useState([])
    const [agencies, setAgencies] = useState([])
    const [squares, setSquares] = useState([])
    const [status, setStatus] = useState([])
    const [tags, setTags] = useState([])
    const [clients, setClients] = useState([])
    const [justifies, setJustifies] = useState([])
    const [teams, setTeams] = useState([])


    const [isExpanded, setIsExpanded] = useState(false);
    const [openFiltersSidebar, setOpenFiltersSidebar] = useState(false)
    const [openSaveFiltersSidebar, setOpenSaveFiltersSidebar] = useState(false)
    const [openListDashesSidebar, setOpenListDashesSidebar] = useState(false)

    const [filters, setFilters] = useState({})
    const [dashesSaved, setDashesSaved] = useState([])
    const [generalDashSelected, setGeneralDashSelected] = useState([])
    const [saveNew, setSaveNew] = useState(false)
    const [confirmDeleteDialog, setConfirmDeleteDialog] = useState(false)

    const [graphs, setGraphs] = useState({
        agencies: {
            type: 'graph'
        },
        clients: {
            type: 'graph'
        },
        users: {
            type: 'graph'
        },
        vehicles: {
            type: 'graph'
        },
        months: {
            type: 'graph'
        },

    })
    const toggleExpand = () => {
        setIsExpanded(!isExpanded);
    };

    useEffect(() => {
        getData()
        getDashes()
        axios.post(
            Constants.APIEndpoints.PROPOSAL + "/getallgeneraldata", {
            entities: ['agencies', 'users', 'clients', 'vehicles', 'squares', 'status', 'tags', 'groups', 'justifies', 'teams']
        })
            .then((res) => {
                setAgencies(res.data.agencies)
                setUsers(res.data.users)
                setClients(res.data.clients)
                setVehicles(res.data.vehicles)
                setSquares(res.data.squares)
                setStatus(res.data.status)
                setTags(res.data.tags);
                setGroups(res.data.groups)
                setJustifies(res.data.justifies)
                setTeams(res.data.teams)
            })

    }, [])

    const getData = (values) => {
        setFilters(values)
        setGeneralDashSelected(prev => ({ ...prev, filters: values }))
        axios.post(Constants.APIEndpoints.DASHBOARD + '/getdashboard', values).then(res => {
            setData(res.data)
            setOpenFiltersSidebar(false)
        })
    }

    const getDashes = () => {
        axios.get(Constants.APIEndpoints.DASHBOARD + '/getgeneraldashes').then(res => {
            setDashesSaved(res.data)
        })
    }

    const saveFilters = (values) => {
        const url = saveNew ? '/savegeneraldash' : '/updategeneraldash'
        axios.post(Constants.APIEndpoints.DASHBOARD + url, values).then(res => {
            setOpenSaveFiltersSidebar(false)
            getDashes()
            setSaveNew(false)
        })
    }

    const loadGeneralDash = (dash) => {
        setGeneralDashSelected(dash)
        getData(JSON.parse(dash.filters || ''))
        setOpenListDashesSidebar(false)
    }

    const changeGraphType = (graph) => {
        const oldType = graphs[graph].type
        setGraphs(prev => ({ ...prev, [graph]: { type: oldType == 'graph' ? 'table' : 'graph' } }))
    }

    const convertValuesProposalsToTable = (valuesProposals) => {
        const translateMonths = {
            '01': 'Janeiro',
            '02': 'Fevereiro',
            '03': 'Março',
            '04': 'Abril',
            '05': 'Maio',
            '06': 'Junho',
            '07': 'Julho',
            '08': 'Agosto',
            '09': 'Setembro',
            '10': 'Outubro',
            '11': 'Novembro',
            '12': 'Dezembro',
        }

        valuesProposals.map(v => {
            v.name = translateMonths[v.month]
        })
        return valuesProposals
    }

    const downloadTableGraph = (values, name) => {
        const file = `${name}-${moment().format('DDMMYYYY_HH:mm:ss')}.xlsx`
        const token = sessionStorage.getItem('token');

        fetch(Constants.APIEndpoints.DASHBOARD + '/downloadtablegraph',
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-Access-Token': token
                },
                body: JSON.stringify({
                    values
                })
            }).then(results => {
                return results.blob()
            }).then(response => {
                saveAs(response, file);
            })
    }

    const deleteGeneralDash = () => {
        axios.delete(Constants.APIEndpoints.DASHBOARD + '/deletegeneraldash/' + generalDashSelected.id_general_dash).then(res => {
            setOpenSaveFiltersSidebar(false)
            getDashes()
            setSaveNew(false)
            setConfirmDeleteDialog(false)
            setGeneralDashSelected({})
        })
    }

    const openDeleteDash = (dash) => {
        setGeneralDashSelected(dash)
        setConfirmDeleteDialog(true)
    }

    return (
        <div>
            {confirmDeleteDialog ? (
                <ConfirmDialog title="Deseja deletar esse filtro?" cancel={() => setConfirmDeleteDialog(false)} confirm={deleteGeneralDash} />
            ) : null}
            <div className={`floating-button-container ${isExpanded ? 'expanded' : ''}`}>
                <button className="toggle-button" onClick={toggleExpand}>
                    {isExpanded ? '✕' : '☰'}
                </button>

                {isExpanded && (
                    <div className="button-group">
                        <button className="action-button" onClick={() => setOpenFiltersSidebar(true)}>FILTROS</button>
                        <button className="action-button" onClick={() => {
                            setOpenSaveFiltersSidebar(true)
                            setSaveNew(false)
                        }}>SALVAR</button>
                        {loggedUser.id_user === generalDashSelected.user_cad && (<button className="action-button" onClick={() => {
                            setOpenSaveFiltersSidebar(true)
                            setSaveNew(true)
                        }
                        }>SALVAR COMO NOVO</button>)}
                        <button className="action-button" onClick={() => setOpenListDashesSidebar(true)}>LISTAR FILTROS SALVOS</button>
                    </div>
                )}
            </div>
            < div style={{ marginTop: 60 }}>
                <FiltersDash
                    open={openFiltersSidebar}
                    onClose={() => setOpenFiltersSidebar(false)}
                    clients={clients}
                    agencies={agencies}
                    users={users}
                    squares={squares}
                    status={status}
                    tags={tags}
                    groups={groups}
                    justifies={justifies}
                    vehicles={vehicles}
                    onSubmit={getData}
                    filters={filters}
                    teams={teams}
                />

                <SaveFiltersDash
                    open={openSaveFiltersSidebar}
                    onClose={() => setOpenSaveFiltersSidebar(false)}
                    users={users}
                    teams={teams}
                    onSubmit={saveFilters}
                    dashesSaved={dashesSaved}
                    generalDashSelected={generalDashSelected}
                    setGeneralDashSelected={setGeneralDashSelected}
                />

                <ListGeneralDashes
                    open={openListDashesSidebar}
                    onClose={() => setOpenListDashesSidebar(false)}
                    loadGeneralDash={loadGeneralDash}
                    openDelete={openDeleteDash}
                    generalDashes={dashesSaved}
                    generalDashSelected={generalDashSelected}
                />


                <Box sx={{ flexGrow: 1 }}>
                    <Grid container spacing={1} >
                        <Grid item xs={4}>
                            <div className="cardDash">
                                <label className="title">TOTAL DAS PROPOSTAS DO PERÍODO</label>
                                <label className="value">{parseFloat(data?.valuesProposalsByMonth?.total || 0).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</label>
                            </div>
                        </Grid>
                        <Grid item xs={4}>
                            <div className="cardDash">
                                <label className="title">TOTAL DA META DO PERÍODO</label>
                                <label className="value">{parseFloat(data?.goal || 0).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</label>
                            </div>
                        </Grid>
                        <Grid item xs={4}>
                            <div className="cardDash">
                                <label className="title">PORCENTAGEM DA META</label>
                                <label className="value">{parseFloat(data?.goalPercent || 0).toFixed(2)}%</label>
                            </div>
                        </Grid>
                        {/* <Grid item xs={6}>
                            <div className="chart">
                                <Last10Leads
                                    data={data.lastcardDashs}
                                    title='Últimos 10 Leads cadastrados'
                                />
                            </div>
                        </Grid>
                        <Grid item xs={6}>
                            <div className="chart">
                                <Last10Proposals
                                    data={data.lastProposals}
                                    title='Últimos 10 Negócios Cadastrados'
                                />
                            </div>
                        </Grid> */}
                        <Grid item xs={12}>
                            <div className="chart">
                                {data.valuesProposals && (
                                    <>
                                        <div className="buttonsGraph">
                                            <IconButton
                                                color="primary"
                                                style={{ borderRadius: 0 }}
                                                onClick={() => changeGraphType('months')}
                                            >
                                                <Autorenew />
                                            </IconButton>
                                            <IconButton
                                                color="primary"
                                                style={{ borderRadius: 0 }}
                                                onClick={() => downloadTableGraph(convertValuesProposalsToTable(data.valuesProposals), 'Por Tempo')}
                                            >
                                                <Icon>download</Icon>
                                            </IconButton>
                                        </div>
                                        {graphs['months'].type == 'graph' ? (
                                            <Chart options={ChartData.formatDataValuesChart(data.valuesProposals, 'Valores totais do ano').options} series={ChartData.formatDataValuesChart(data.valuesProposals, 'Valores totais do ano').series} type="bar" height={550} />

                                        ) : (
                                            <ListDataDashboard
                                                name={'Meses'}
                                                data={convertValuesProposalsToTable(data.valuesProposals)}
                                            />
                                        )}
                                    </>
                                )}
                            </div>
                        </Grid>
                        {/* <Grid item xs={3}>
                            <div className="chart">
                                {data.groupStatus && (
                                    <Chart options={ChartData.formatDataProgressChart(data.groupStatus, 'status', '% De Negócios Aprovados').options} series={ChartData.formatDataProgressChart(data.groupStatus, 'status', '% De Negócios Aprovados').options.series} type="radialBar" height={250} />
                                )}
                            </div>
                        </Grid> */}
                        {/* <Grid item xs={4}>
                            <div className="chart">
                                {data.leadsWins && (
                                    <>

                                        <Chart options={ChartData.formatDataProgressChart(data.leadsWins, 'leads', '% De Leads Ganhos').options} series={ChartData.formatDataProgressChart(data.leadsWins, 'leads', '% De Leads Ganhos').options.series} type="radialBar" height={250} />

                                    </>
                                )}
                            </div>
                        </Grid> */}
                        <Grid item xs={6}>
                            <div className="chart">
                                {data.groupAgency?.graph && (
                                    <>
                                        <div className="buttonsGraph">
                                            <IconButton
                                                color="primary"
                                                style={{ borderRadius: 0 }}
                                                onClick={() => changeGraphType('agencies')}
                                            >
                                                <Autorenew />
                                            </IconButton>
                                            <IconButton
                                                color="primary"
                                                style={{ borderRadius: 0 }}
                                                onClick={() => downloadTableGraph(data.groupAgency.table, 'Por Agências')}
                                            >
                                                <Icon>download</Icon>
                                            </IconButton>
                                        </div>
                                        {graphs['agencies'].type == 'graph' ? (
                                            <Chart options={ChartData.formatDataByValuesChart(data.groupAgency.graph, 'Agências').options} series={ChartData.formatDataByValuesChart(data.groupAgency.graph, '').series} type="bar" height={550} />

                                        ) : (
                                            <ListDataDashboard
                                                name={'Agências'}
                                                data={data.groupAgency.table}
                                            />
                                        )}
                                    </>
                                )}
                            </div>
                        </Grid>
                        <Grid item xs={6}>
                            <div className="chart">
                                {data.groupClient?.graph && (
                                    <>
                                        <div className="buttonsGraph">
                                            <IconButton
                                                color="primary"
                                                style={{ borderRadius: 0 }}
                                                onClick={() => changeGraphType('clients')}
                                            >
                                                <Autorenew />
                                            </IconButton>
                                            <IconButton
                                                color="primary"
                                                style={{ borderRadius: 0 }}
                                                onClick={() => downloadTableGraph(data.groupClient.table, 'Por Clientes')}
                                            >
                                                <Icon>download</Icon>
                                            </IconButton>
                                        </div>

                                        {graphs['clients'].type == 'graph' ? (
                                            <Chart options={ChartData.formatDataByValuesChart(data.groupClient.graph, 'Clientes').options} series={ChartData.formatDataByValuesChart(data.groupClient.graph, 'category').series} type="bar" height={550} />
                                        ) : (
                                            <ListDataDashboard
                                                name={'Clientes'}
                                                data={data.groupClient.table}
                                            />
                                        )}

                                    </>
                                )}
                            </div>
                        </Grid>
                        <Grid item xs={6}>
                            <div className="chart">
                                {data.groupVehicle?.graph && (
                                    <>
                                        <div className="buttonsGraph">
                                            <IconButton
                                                color="primary"
                                                style={{ borderRadius: 0 }}
                                                onClick={() => changeGraphType('vehicles')}
                                            >
                                                <Autorenew />
                                            </IconButton>
                                            <IconButton
                                                color="primary"
                                                style={{ borderRadius: 0 }}
                                                onClick={() => downloadTableGraph(data.groupVehicle.table, 'Por Agências')}
                                            >
                                                <Icon>download</Icon>
                                            </IconButton>
                                        </div>
                                        {graphs['vehicles'].type == 'graph' ? (
                                            <Chart options={ChartData.formatDataByValuesChart(data.groupVehicle.graph, 'Veículos').options} series={ChartData.formatDataByValuesChart(data.groupVehicle.graph, 'category').series} type="bar" height={550} />
                                        ) : (
                                            <ListDataDashboard
                                                name={'Veículos'}
                                                data={data.groupVehicle.table}
                                            />
                                        )}
                                    </>
                                )}
                            </div>
                        </Grid>
                        <Grid item xs={6}>
                            <div className="chart">
                                {data.groupResponsible?.graph && (
                                    <>
                                        <div className="buttonsGraph">
                                            <IconButton
                                                color="primary"
                                                style={{ borderRadius: 0 }}
                                                onClick={() => changeGraphType('users')}
                                            >
                                                <Autorenew />
                                            </IconButton>
                                            <IconButton
                                                color="primary"
                                                style={{ borderRadius: 0 }}
                                                onClick={() => downloadTableGraph(data.groupResponsible.table, 'Por Executivos')}
                                            >
                                                <Icon>download</Icon>
                                            </IconButton>
                                        </div>
                                        {graphs['users'].type == 'graph' ? (
                                            <Chart options={ChartData.formatDataByValuesChart(data.groupResponsible.graph, 'Executivos').options} series={ChartData.formatDataByValuesChart(data.groupResponsible.graph, 'category').series} type="bar" height={550} />
                                        ) : (
                                            <ListDataDashboard
                                                name={'Executivos'}
                                                data={data.groupResponsible.table}
                                            />
                                        )}
                                    </>
                                )}
                            </div>
                        </Grid>
                    </Grid>
                </Box>

            </div >
        </div >
    )
}