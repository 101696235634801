import React, { useEffect, useState } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import moment from 'moment-timezone';
import { Divider } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import clsx from 'clsx';
import Typography from '@material-ui/core/Typography';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import Icon from "@material-ui/core/Icon";
import IconButton from "@material-ui/core/IconButton";
import Constants from 'app/utils/Constants';
import axios from 'app/utils/AxiosConfig';
import ConfirmDialog from "app/components/dialog/ConfirmDialog";
import WinCard from './winCard';
import './Leads.css'
import CommonDialog from "app/components/dialog/CommonDialog";
import CommonForm from "app/components/form/CommonForm";
import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import { eventBus } from 'app/utils/EventListener'
const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: 'rgba(255, 255, 255, 1)',
        color: 'rgba(20, 0, 0, 1)',
        opacity: 1,
        fontSize: 11,
        borderRadius: 10,
        border: '1px solid'
    },
}));

export default function BoardCard({
    getLeads,
    lead,
    column,
    selectedLead,
    index,
    getSingleLead,
    setOpenCreateNewCardWithStep,
    setOpenLead,
    setSelectedLead
}) {
    if (lead && lead.tags) {
        lead.tags_ = lead.tags.map(t => t.id_tags)
    }
    if (lead) {
        lead.dt_start = lead.dt_start ? moment(lead.dt_start).format('YYYY-MM-DD') : ''
        lead.dt_end = lead.dt_end ? moment(lead.dt_end).format('YYYY-MM-DD') : ''
    }

    if (lead && selectedLead) {
        lead.files = selectedLead.files
    }

    const [deleteDialog, setDeleteDialog] = useState(false)
    const [lossCardDialog, setLossCardDialog] = useState(false)

    useEffect(() => {
        eventBus.on('lose-modal-card', (data) => {
            setSelectedLead([])
            setOpenLead(false)
        })
        eventBus.on('won-modal-card', (data) => {
            setOpenLead(false)
            setSelectedLead([])
        })
    }, [])

    const deleteLead = () => {
        axios.delete(Constants.APIEndpoints.LEADS + "/deletelead/" + lead.id_leads).then(res => {
            getLeads({}, column.id_kanban_column)
            setSelectedLead([])
            setDeleteDialog(false)
        })
    }

    const infoActTooltip = (activities) => {
        const data = activities.map(activity => (
            <div style={{
                display: 'contents'
            }}>
                <Typography
                    style={{
                        fontSize: 12,
                        textAlign: 'center',
                        fontWeight: 600,
                        width: '100%',
                        padding: 10
                    }}
                >{activity.name}</Typography>
                <div
                    style={{
                        display: 'flex',
                        border: '1px solid rgba(0,0,0,25%)',
                        borderRadius: 5,
                        padding: 5
                    }}>
                    <FuseSvgIcon color="action">
                        heroicons-outline:clock
                    </FuseSvgIcon>
                    <Typography className="text-12 font-semibold"
                        style={{
                            marginLeft: 10,
                            fontSize: 10,
                            fontWeight: 600,
                            textAlign: 'start',
                            width: '100%',
                            marginTop: 5
                        }}>{activity.dt_start ? moment(activity.dt_start).format('DD/MM/YYYY HH:mm') + ' à ' : ''}  {activity.dt_end ? moment(activity.dt_end).format('DD/MM/YYYY HH:mm') : ''}</Typography>
                </div>
                <Divider style={{ marginTop: 5, height: 1, backgroundColor: 'black' }} />
            </div>
        ))
        return data
    }


    const lossCard = (values) => {
        values.fk_id_card = lead.id_leads
        axios.post(Constants.APIEndpoints.KANBAN + '/losscard', values).then(res => {
            setLossCardDialog(false)
            setOpenLead(false)
            getSingleLead(lead.id_leads)
        })
    }

    return (
        <div>
            <Draggable draggableId={JSON.stringify(lead)} index={index} type="card">
                {(provided, snapshot) => (
                    <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                        <Card
                            className={clsx(
                                snapshot.isDragging ? 'shadow-lg' : 'shadow-0',
                                lead.status == 'finished' ? 'winCard' : '',
                                lead.status == 'not_realized' ? 'notrealizedCard' : '',
                                'w-full mb-16 rounded-8  cardKanban'
                            )}
                        >
                            <div style={{ display: 'flex', backgroundColor: '#1b2330', height: 'auto' }}>
                                <Typography onClick={(ev) => {
                                    getSingleLead(lead.id_leads)
                                    setOpenLead(true)
                                }}
                                    className="subjectCard">{lead.campaign}
                                </Typography>
                                <div style={{ display: 'flex' }}>
                                    <IconButton
                                        style={{ width: 10 }}
                                        onClick={(ev) => {
                                            getSingleLead(lead.id_leads, true)
                                            setOpenLead(false)
                                        }}
                                    >
                                        <Icon style={{ color: 'white', fontSize: 18 }}>edit</Icon>
                                    </IconButton>
                                    <IconButton
                                        style={{ width: 10 }}
                                        onClick={(ev) => {
                                            setDeleteDialog(true)
                                        }}
                                    >
                                        <Icon style={{ color: 'white', fontSize: 18 }}>delete</Icon>
                                    </IconButton>
                                </div>
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <div className='cardContent' onClick={(ev) => {
                                    setOpenLead(true)
                                    getSingleLead(lead.id_leads)
                                }}>
                                    <Tooltip title={<h4>Quantidade de Propostas</h4>}>
                                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                            <Typography style={{ color: 'black', fontWeight: 'bold' }}>{lead.total_proposals_progress}</Typography>
                                            <Typography style={{ color: 'green', fontWeight: 'bold' }}>{lead.total_proposals_win}</Typography>
                                            <Typography style={{ color: 'red', fontWeight: 'bold' }}>{lead.total_proposals_loss}</Typography>
                                        </div>
                                    </Tooltip>
                                    <Typography>{lead.client}</Typography>
                                    <Typography>{lead.agency}</Typography>
                                    <Typography>{lead.value ? parseFloat(lead.value).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) : ''}
                                    </Typography>
                                    <Typography >{`${lead.dt_start ? moment(lead.dt_start).format('DD/MM/YYYY') : 'Sem data de início'} à ${lead.dt_end ? moment(lead.dt_end).format('DD/MM/YYYY') : 'Sem data de término'}`}
                                    </Typography>

                                </div>
                                <div>
                                    {lead.has_delay_activity && (
                                        <Tooltip title={<h4>Possui atividade atrasada</h4>}>
                                            <FuseSvgIcon
                                                style={{ color: '#a10404', margin: 2 }}
                                            >heroicons-outline:exclamation-circle</FuseSvgIcon>
                                        </Tooltip>
                                    )}
                                </div>
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                    {lead.tags && lead.tags.map(t => {
                                        return (
                                            <div style={{
                                                backgroundColor: t.color, height: 20, minWidth: 60, maxWidth: 'fit-content', padding: 10
                                                , margin: 4, borderRadius: 10, display: 'flex', color: 'white', fontSize: 10,
                                                justifyContent: 'center', alignItems: 'center'
                                            }}>{t.name}</div>
                                        )
                                    })}
                                </div>

                                <div>
                                    {lead.activities && lead.activities.length > 0 && (
                                        <LightTooltip
                                            title={infoActTooltip(lead.activities)}>
                                            <FuseSvgIcon
                                                style={{ margin: 2 }}
                                            >heroicons-outline:information-circle</FuseSvgIcon>
                                        </LightTooltip>
                                    )
                                    }

                                </div>
                            </div>
                        </Card >
                    </div >
                )
                }
            </Draggable >


            <CommonDialog
                open={lossCardDialog}
                onClose={() => setLossCardDialog(false)}
                title="Perda"
                width="md"
                fullWidth={true}
            >
                <CommonForm
                    fields={[
                        {
                            col: 12,
                            name: 'loss',
                            label: 'Justificativa',
                            type: 'textarea',
                        },
                    ]}
                    onSubmit={lossCard}
                />

            </CommonDialog>

            {
                deleteDialog ? (
                    <ConfirmDialog title="Deseja deletar este lead?" cancel={() => setDeleteDialog(false)} confirm={deleteLead} />
                ) : null
            }
        </div >
    )
}